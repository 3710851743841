import { intervalToDuration } from 'date-fns';

export function generateAndDownloadCSV(users, startDate, endDate) {
  let csvContent = '';
  let fileDate;
  let startDateInDateFormat = new Date(startDate)
    .toLocaleDateString()
    .replaceAll('/', '_')
    .replaceAll(',', '')
    .replaceAll(' ', '_')
    .replaceAll(':', '_');
  let endDateInDateFormat = new Date(endDate)
    .toLocaleDateString()
    .replaceAll('/', '_')
    .replaceAll(',', '')
    .replaceAll(' ', '_')
    .replaceAll(':', '_');

  if (startDate && endDate)
    fileDate = `${startDateInDateFormat}_to_${endDateInDateFormat}`;
  else
    fileDate = new Date()
      .toLocaleString()
      .replaceAll('/', '_')
      .replaceAll(',', '')
      .replaceAll(' ', '_')
      .replaceAll(':', '_');

  let csvFileName = `ezVoltz_users_${fileDate}.csv`;

  if (users.length === 0) {
    console.error('No users data provided for CSV export.');
    return;
  }

  const header = 'name,email,phone,lastActiveAt,optin/optout';
  csvContent += header + '\r\n';

  users.forEach((user) => {
    const values = [
      user.name,
      user.email,
      user.phone,
      user.lastActiveAt || user.lastLoginAt || user.createdAt || 'N/A',
      user.isRegisterConsent,
    ]
      .map((value, index) => {
        if (index === 2) return `'${value}'`;
        return value;
      })
      .join(',');
    csvContent += values + '\r\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', csvFileName);

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

export const initialValues = {
  startFrom: null,
  destination: null,
  inBetween: [],
  startDate: null,
  startTime: null,
  avoidTolls: false,
  avoidTraffic: false,
  avoidHighways: false,
  hotels: false,
  restaurants: false,
  campGround: false,
  vehicle: null,
  chargersType: 'allChargers',
  connectorType: 'all',
  network: 'all',
  distance: '',
  time: '',
  speed: '',
  energy: '',
  cost: '',
};

export const initialFilters = {
  fuel: 'all',
  owner: 'all',
  network: 'all',
  connector: 'all',
  previousStation: false,
  ev_dc_fast_num: true,
  ev_level1_evse_num: true,
  ev_level2_evse_num: true,
};

export const initialReviewUpdateValues = {
  isModal: false,
  data: null,
  url: '',
};

export const secondsToDHMS = (seconds) => {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);

  let dDisplay = d > 0 ? d + (+d === 1 ? ' day, ' : ' days, ') : '';
  let hDisplay = h > 0 ? h + (+h === 1 ? ' hour, ' : ' hours, ') : '';
  let mDisplay = m > 0 ? m + (+m === 1 ? ' minute, ' : ' minutes') : '';
  return dDisplay + hDisplay + mDisplay;
};

export function dateAgo(pastDate) {
  let difference = intervalToDuration({
    start: new Date(),
    end: new Date(pastDate),
  });
  return difference?.years > 0
    ? `${difference.years} ${difference.years === 1 ? 'y' : 'y'}`
    : difference.months > 0
    ? `${difference.months} ${difference.months === 1 ? 'm' : 'm'}`
    : difference.days > 0
    ? `${difference.days} ${difference.days === 1 ? 'd' : 'd'}`
    : difference.hours > 0
    ? `${difference.hours} ${difference.hours === 1 ? 'h' : 'h'}`
    : `${difference.minutes} ${difference.minutes === 1 ? 'm' : 'm'}`;
}

export function dateComing(upcomingDate) {
  if (!upcomingDate) return '';
  let difference = intervalToDuration({
    start: new Date(upcomingDate),
    end: new Date(),
  });
  return `${
    difference?.years > 0
      ? `${difference.years} year${difference.years === 1 ? '' : 's'}`
      : ''
  }  ${
    difference.months > 0
      ? `${difference.months} month${difference.months === 1 ? '' : 's'}`
      : ''
  }  ${
    difference.days > 0
      ? `${difference.days} day${difference.days === 1 ? '' : 's'}`
      : ''
  }`;
}

export function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

export function filterAndSortUpcomingTrips(trips) {
  const upcomingTrips = trips?.filter((trip) => {
    const currentDate = new Date();

    if (trip.status !== 'upcoming') return false;

    if (!trip.startDate && !trip.startTime) return true;
    if (trip.startTime && new Date(trip.startTime) > currentDate) return true;

    if (trip.startDate) {
      const tripStartDate = new Date(trip.startDate);

      if (
        tripStartDate.getFullYear() > currentDate.getFullYear() ||
        (tripStartDate.getFullYear() === currentDate.getFullYear() &&
          tripStartDate.getMonth() > currentDate.getMonth()) ||
        (tripStartDate.getFullYear() === currentDate.getFullYear() &&
          tripStartDate.getMonth() === currentDate.getMonth() &&
          tripStartDate.getDate() > currentDate.getDate())
      ) {
        return true;
      }

      if (
        tripStartDate.getFullYear() === currentDate.getFullYear() &&
        tripStartDate.getMonth() === currentDate.getMonth() &&
        tripStartDate.getDate() === currentDate.getDate() &&
        !trip.startTime
      ) {
        return true;
      }
    }

    return false;
  });

  return upcomingTrips
    ?.sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .reverse();
}

export function filterAndSortPreviousTrips(trips) {
  const currentDate = new Date();

  const filteredTrips = trips?.filter((trip) => {
    if (!trip.startTime && !trip.startDate && trip?.status === 'upcoming')
      return false; // Exclude trips without start time or start date

    if (trip.startDate && !trip.startTime) {
      const tripStartDate = new Date(trip.startDate);
      const currentDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return tripStartDate < currentDay || trip.status !== 'upcoming';
    }

    const tripStart = trip.startTime || trip.startDate;

    return new Date(tripStart) < currentDate || trip.status !== 'upcoming';
  });

  return filteredTrips?.sort((a, b) => {
    if (a.status === 'inprogress' && b.status !== 'inprogress') {
      return -1; // 'in progress' comes first
    }
    if (a.status !== 'inprogress' && b.status === 'inprogress') {
      return 1; // 'in progress' comes first
    }
    return new Date(b.startDate) - new Date(a.startDate);
  });
}

export const tripStatus = {
  isCompleted: 'completed',
  inProgress: 'inprogress',
  isCancelled: 'cancelled',
  notStarted: 'notStarted',
};
