import { useEffect, useState } from 'react';

import { BasicTable } from 'components/BasicTable/BasicTable';
import PrimaryBtn from 'components/btns/PrimaryBtn';
import { TRIPSCOLUMNS } from 'helper/data';
import PlanTrip from 'modals/planTrip/PlanTrip';
import useApiHook from 'hooks/useApiHook';
import Spiner from 'helper/Spiner';

const Trips = () => {
  const [isPlanModal, setIsPlanModal] = useState(false);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [updateModalOpen, setUpdateModalOpen] = useState(false);
  // const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState({});
  const { handleApiCall, isApiLoading } = useApiHook();
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [trips, setTrips] = useState([]);

  const getAllTrips = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `/admin/trip/all?page=${page}&pageSize=${pageCount}`,
    });

    if (result.status === 200) {
      setTrips(result?.data?.trips);
      setCount(result?.data?.count);
    }
  };

  useEffect(() => {
    getAllTrips();
  }, []);

  useEffect(() => {
    getAllTrips();
  }, [page, pageCount, selectedTrip]);

  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10'>
      <div className='flex flex-row justify-between items-center'>
        <h1 className='text-2xl font-semibold text-ezBlack'>Trips</h1>
        <PrimaryBtn
          btnType='submit'
          text='Plan a Trip'
          width
          handleEvent={() => setIsPlanModal(true)}
        />
      </div>

      {trips.length > 0 ? (
        <div className=' w-full block card bg-white rounded-md shadow-sm  border'>
          <BasicTable
            COLUMNS={TRIPSCOLUMNS}
            ROWS={trips}
            paginationText='Trip'
            isApiLoading={isApiLoading}
            view='trips'
            searchText='Search Trip'
            actions={true}
            setSelectedValue={setSelectedTrip}
            // setEditModalOpen={setUpdateModalOpen}
            // setDeleteModalOpen={setDeleteModalOpen}
            pageNumber={page}
            setPageNumber={setPage}
            pageCount={pageCount}
            setPageCount={setPageCount}
            totalCount={count}
          />
        </div>
      ) : isApiLoading ? (
        <div className='col-span-3 block w-full h-96'>
          <Spiner color='ezGreen' />
        </div>
      ) : null}

      {isPlanModal && (
        <PlanTrip isPlanModal={isPlanModal} setIsPlanModal={setIsPlanModal} />
      )}
    </div>
  );
};

export default Trips;
