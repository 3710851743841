import React from 'react';
import { BsArrowUpShort, BsArrowDownShort } from 'react-icons/bs';

const Card = ({ data }) => {
  const isValueIncreased = data.desc.includes('increase') ? true : false;
  const isValueSame = data.desc.includes('same') ? true : false;
  const isValueZero = data.desc.split(' ')[0] === '0%' ? true : false;

  const textColorClass = isValueZero
    ? 'text-blue-500'
    : isValueIncreased
    ? 'text-green-500'
    : 'text-red-500';

  const arrowIcon =
    isValueSame ? null : isValueZero ? null : isValueIncreased ? (
      <BsArrowUpShort className='text-green-500 w-7' />
    ) : (
      <BsArrowDownShort className='text-red-500 w-7' />
    );

  return (
    <div className='card bg-white rounded-md shadow-sm mt-1 p-3 flex flex-col gap-2'>
      <h3 className='text-base'>{data.label}</h3>
      <h1 className='text-2xl font-bold'>{data.value}</h1>
      <div className='flex items-center'>
        <p className={`text-sm ${textColorClass}`}>
          {isValueSame ? null : data.desc}
        </p>
        <span>{arrowIcon}</span>
      </div>
    </div>
  );
};

export default Card;
