import React, { useState, useEffect, useRef } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { toggleEmptyFilters } from "redux/dashbbaord/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { selectedUser } from "redux/auth/authSlice";

const Dropdown = ({ dropdownText, icon, options=false, value, onSelect, setShowValue, setEditModalOpen, setDeleteModalOpen }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const { dashboard } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  
  const menuItems = options ? options.map((option) => {
    return { 
      label: option, 
      action: () => {
        onSelect(option);
        setSelectedOption(option);
        toggleDropdown();
      }
    }
  }) : [
    { label: "View", action: () => {onSelect(value); handleView(value); toggleDropdown();} },
    { label: "Edit", action: () => {onSelect(value); setEditModalOpen(true); toggleDropdown();} },
    { label: "Delete", action: () => {onSelect(value); setDeleteModalOpen(true); toggleDropdown();} }
  ];

  const handleView = (user) => {
    dispatch(selectedUser(user));
    navigate(`/users/${user._id}`);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (dashboard?.isEmptyFilters) {
      setSelectedOption('');
      dispatch(toggleEmptyFilters(false));
    }
  }, [dashboard?.isEmptyFilters]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isDropdownOpen && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isDropdownOpen]);

  return (
    <div className='relative inline-block text-left' ref={dropdownRef}>
      <div>
        <button
          type='button'
          className={`inline-flex w-full justify-center ${selectedOption ? "text-black" : "text-ezLightGray"}
          ${
            icon
              ? ""
              : "gap-7 rounded-md bg-ezMidWhite px-3 py-2 text-sm focus:outline-none shadow-sm"
          } `}
          id='menu-button'
          aria-expanded={isDropdownOpen}
          aria-haspopup='true'
          onClick={toggleDropdown}>
          {icon ? (
            <FiMoreVertical className='w-5 h-5' />
          ) : (
            <>
              {selectedOption ? selectedOption : dropdownText}
              <svg
                className={`-mr-1 h-5 w-5 text-gray-400 ${
                  isDropdownOpen ? "transform rotate-180" : ""
                }`}
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'>
                <path
                  fillRule='evenodd'
                  d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                  clipRule='evenodd'
                />
              </svg>
            </>
          )}
        </button>
      </div>

      {isDropdownOpen && (
        <div
          className='absolute right-0 z-50 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          role='menu'
          aria-orientation='vertical'
          aria-labelledby='menu-button'
          tabIndex={-1}>
          {menuItems.map((item, index) => (
            <button
              className='text-gray-700 block w-36 text-left px-4 py-2 text-sm hover:bg-ezLightGreen'
              onClick={item.action}
              role='menuitem'
              tabIndex={-1}
              key={index}>
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
