import Loader from 'helper/Loader';
import useApiHook from 'hooks/useApiHook';
import ReviewImagesModal from 'modals/review/ReviewImagesModal';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BiPhone, BiMailSend } from 'react-icons/bi';
import { AiFillEye, AiOutlineLeft } from 'react-icons/ai';
import Avatar from 'react-avatar';

const FeedbackDetail = () => {
  const [isReviewImageModal, setIsReviewImageModal] = useState(false);
  const [feedbackDetail, setFeedbackDetail] = useState(null);

  const { handleApiCall, isApiLoading } = useApiHook();
  const { feedbackId } = useParams();

  const getFeebackDetail = async () => {
    const result = await handleApiCall({
      method: 'GET',
      url: `/feedback/${feedbackId}`,
    });
    if (result.status === 200) setFeedbackDetail(result?.data?.feedback);
  };

  useEffect(() => {
    if (feedbackId) {
      getFeebackDetail();
    }
  }, [feedbackId]);

  return (
    <div className='ez__Settings bg-ezMidWhite px-4 py-10 md:p-10'>
      {isApiLoading && <Loader background='transparency' />}
      <div className='ez__Title w-full flex md:items-center justify-between mb-8 flex-col md:flex-row'>
        <h3 className='text-ezBlack text-xl mb-3 md:mb-0 flex items-center'>
          <Link to='/dashboard/feedback' className='mr-3'>
            <AiOutlineLeft />
          </Link>
          Feedback Detail:
        </h3>
      </div>
      <div className=' grid grid-cols-1 xl:grid-cols-4 gap-4'>
        <div className='card block w-full xl:col-span-3 p-6 bg-white rounded-md shadow-sm'>
          <h3 className='text-base font-semibold leading-7 text-gray-900'>
            {feedbackDetail?.title}
          </h3>
          <div className='mt-6 border-t border-gray-100'>
            <dl className='divide-y divide-gray-100'>
              <div className='py-6 sm:grid sm:grid-cols-3 sm:gap-4'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>
                  Description
                </dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {feedbackDetail?.description}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className='col-span-1'>
          <ul>
            <li className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow'>
              <div className='flex flex-1 flex-col p-8 items-center'>
                {feedbackDetail?.user?.profileImage ? (
                  <img
                    className='mx-auto h-32 w-32 flex-shrink-0 rounded-full'
                    src={feedbackDetail?.user?.profileImage}
                    alt='User Profile'
                  />
                ) : (
                  <Avatar name={feedbackDetail?.user?.name} size='128' round />
                )}
                <h3 className='mt-6 text-sm font-medium text-gray-900'>
                  {feedbackDetail?.user?.name}
                </h3>
                <p className='mt-6 text-xs font-medium text-gray-900'>
                  {feedbackDetail?.user?.email}
                </p>
              </div>
              <div>
                <div className='-mt-px flex divide-x divide-gray-200'>
                  <div className='flex w-0 flex-1'>
                    <a
                      href={`mailto:${feedbackDetail?.user?.email}`}
                      className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
                    >
                      <BiMailSend
                        className='h-5 w-5 divide-gray-200'
                        aria-hidden='true'
                      />
                      Email
                    </a>
                  </div>
                  <div className='-ml-px flex w-0 flex-1'>
                    <a
                      href={`tel:${feedbackDetail?.user?.phone}`}
                      className='relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900'
                    >
                      <BiPhone
                        className='h-5 w-5 divide-gray-200'
                        aria-hidden='true'
                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {feedbackDetail?.images?.length > 0 && (
        <div className=' grid grid-cols-1 xl:grid-cols-4 gap-4'>
          <div className='card block p-6 xl:col-span-3 bg-white rounded-md shadow-sm mt-5'>
            <h3 className='text-base font-semibold leading-7 text-gray-900 mb-5'>
              Images:
            </h3>
            <div className='grid grid-cols-2 md:grid-cols-6 gap-4'>
              {feedbackDetail?.images.map((img, index) => (
                <div
                  key={index}
                  className='cursor-pointer relative group groupBlur  rounded-md overflow-hidden'
                  onClick={() => setIsReviewImageModal(true)}
                >
                  <img
                    className='mx-auto h-32 w-full object-cover image-blur'
                    src={img}
                    alt='review images'
                  />
                  <div className='absolute inset-0 flex items-center justify-center bg-ezBlack bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity'>
                    <AiFillEye className='w-8 h-8 text-white' />
                  </div>
                </div>
              ))}
              {isReviewImageModal && (
                <ReviewImagesModal
                  isReviewImageModal={isReviewImageModal}
                  setIsReviewImageModal={setIsReviewImageModal}
                  images={feedbackDetail?.images}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackDetail;
