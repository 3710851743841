import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import GrayBtn from "components/btns/GrayBtn";
import { colourStyles } from "helper/helper";

import Select from "react-select";

const PlanTripStepSelectOrg = ({
  formValues,
  setFormValues,
  setSteps,
  directions,
  setDirections,
  setTripDirections,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const valid = () => {
    // if (formValues?.startFrom && formValues?.destination) return true;
    return true;
  };

  const getDirections = async ({ origin, destination }) => {
    return true;
  };

  return (
    <div className='bg-white w-full py-6 px-4 md:px-12'>
      <div className='grid grid-cols-1 md:grid-cols-11 items-center gap-6 md:gap-0'>
        <div className='ez__StepImg w-full block col-span-5'>
          <img
            src='/assets/images/carImg.jpg'
            alt='Car Wheel'
            className='w-full h-80  object-cover md:object-none  border-2 border-ezGreen rounded-lg my-3'
          />
        </div>
        <div className='hidden md:block w-px h-full bg-ezLightGray mx-auto' />
        <div className='ez__StepForm w-full col-span-5'>
          <div className='block relative w-full items-center'>
            <div className='relative w-full text-sm sm:text-base text-ezGray'>
              <Select
                className='basic-single mb-4'
                classNamePrefix='select'
                placeholder='Select Organization'
                isSearchable={true}
                styles={colourStyles}
                name='org'
              />
            </div>
          </div>
          <Select
            className='basic-single mb-4'
            classNamePrefix='select'
            placeholder='Select User'
            isSearchable={true}
            styles={colourStyles}
            name='user'
          />
          <GrayBtn
            isDisabled={!valid() && !isLoading}
            handleEvent={() => setSteps(2)}
            classNameCondition={valid() === true && !isLoading}
            text='Next Step'
          />
        </div>
      </div>
    </div>
  );
};

export default PlanTripStepSelectOrg;
