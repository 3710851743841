import React, { useState } from "react";
import PrimaryBtn from "components/btns/PrimaryBtn";
import { VEHICLE_COLUMNS, VEHICLE_DATA } from "helper/data";

import { BasicTable } from "components/BasicTable/BasicTable";
import AddVehicle from "modals/vehicles/AddVehicle";

const Vehicles = () => {
  const [addUserModal, setIsAddUserModal] = useState(false);

  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10'>
      <div className='flex flex-row justify-between items-center'>
        <h1 className='text-2xl font-semibold text-ezBlack'>Vehicles</h1>
        <PrimaryBtn
          btnType='submit'
          text='Add Vehicle'
          width
          handleEvent={() => setIsAddUserModal(true)}
        />
      </div>
      <div className=' w-full block card bg-white rounded-md shadow-sm  border'>
        <BasicTable
          COLUMNS={VEHICLE_COLUMNS}
          ROWS={VEHICLE_DATA}
          paginationText='Vehicles'
          view='vehicles'
          searchText='Search trip ID'
        />
      </div>
      {addUserModal && (
        <AddVehicle isVehicleModal={addUserModal} setIsVehicleModal={setIsAddUserModal} />
      )}{" "}
    </div>
  );
};

export default Vehicles;
