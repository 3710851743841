import React, { useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { useDispatch } from 'react-redux';
import Dropdown from 'components/Dropdown';
import Search from 'components/Search';
import OutlineBtn from 'components/btns/OutlineBtn';
import { toggleEmptyFilters } from 'redux/dashbbaord/dashboardSlice';
import { BiLoaderCircle } from 'react-icons/bi';

export const BasicTable = ({
  COLUMNS,
  ROWS,
  actions = false,
  paginationText,
  searchText,
  isApiLoading = false,
  view,
  setSelectedValue,
  setShowValue,
  setDeleteModalOpen,
  setEditModalOpen,
  pageNumber,
  setPageNumber,
  pageCount,
  setPageCount,
  totalCount,
  setSearch,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    filter1: null,
    filter2: null,
  });
  const [searchValue, setSearchValue] = useState('');
  const [displayedPageCount, setDisplayedPageCount] = useState(1);
  const dispatch = useDispatch();

  const columns = useMemo(() => {
    let columnsArray = [...COLUMNS];
    if (actions) {
      columnsArray.push({
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Dropdown
            value={row.original}
            setDeleteModalOpen={setDeleteModalOpen}
            setEditModalOpen={setEditModalOpen}
            setShowValue={setShowValue}
            onSelect={(value) => setSelectedValue(value)}
            icon
          />
        ),
      });
    }
    return columnsArray;
  }, [
    COLUMNS,
    actions,
    setDeleteModalOpen,
    setEditModalOpen,
    setSelectedValue,
    setShowValue,
  ]);

  const data = useMemo(() => {
    let filteredData = [...ROWS];
    if (selectedFilters.filter1 || selectedFilters.filter2) {
      filteredData = filteredData.filter((data) => {
        return (
          data?.make?.name?.includes(selectedFilters.filter1) ||
          data?.model?.model?.includes(selectedFilters.filter2)
        );
      });
    }
    return filteredData;
  }, [selectedFilters, ROWS]);

  const pageOptions = useMemo(() => {
    const totalItems = totalCount;
    const roundedTotal = Math.ceil(totalItems / 10) * 10;

    return Array.from(
      { length: roundedTotal / 10 },
      (_, index) => (index + 1) * 10
    );
  }, [totalCount]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageSize },
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
      manualPagination: true,
      pageCount: Math.ceil(ROWS.length / 10),
    },
    useGlobalFilter,
    usePagination
  );

  const statusClasses = {
    'In Progress': 'status-in-progress',
    Completed: 'status-completed',
    Upcoming: 'status-upcoming',
    'Not Scheduled': 'status-not-scheduled',
    Pending: 'status-in-progress',
    Accept: 'status-completed',
    Charging: 'status-in-progress',
    Complete: 'status-completed',
  };

  let VIEW_TO_FILTER = {
    // users: ["SEARCH", "ORG_DROPDOWN", "RESET_FILTER_BTN"],
    users: ['SEARCH', 'RESET_FILTER_BTN'],
    trips: ['SEARCH', 'START', 'END', 'RESET_FILTER_BTN'],
    vehicles: ['SEARCH', 'MAKE_DROPDOWN', 'MODEL_DROPDOWN', 'RESET_FILTER_BTN'],
    organization: [
      'SEARCH',
      'CITY_DROPDOWN',
      'STATE_DROPDOWN',
      'RESET_FILTER_BTN',
    ],
    orgVehicle: ['SEARCH', 'RESET_FILTER_BTN'],
    payment: ['SEARCH'],
    ads: ['SEARCH'],
  };

  const TYPE_TO_FILTER_COMPONENT = {
    SEARCH: (
      <Search
        searchText={searchText}
        value={searchValue}
        onChange={(e) => {
          setSearch(e);
          setSearchValue(e);
        }}
      />
    ),
    ORG_DROPDOWN: <Dropdown dropdownText='Organizations' />,
    MAKE_DROPDOWN: (
      <Dropdown
        dropdownText='Make'
        options={[...new Set(ROWS.map((vehicle) => vehicle?.make?.name))]}
        onSelect={(value) => {
          setSelectedFilters({ filter1: value });
        }}
      />
    ),
    MODEL_DROPDOWN: (
      <Dropdown
        dropdownText='Model'
        options={[...new Set(ROWS.map((vehicle) => vehicle?.model?.model))]}
        onSelect={(value) => {
          setSelectedFilters({ filter2: value });
        }}
      />
    ),
    // CITY_DROPDOWN: <Dropdown dropdownText='City' />,
    // STATE_DROPDOWN: <Dropdown dropdownText='State' />,
    RESET_FILTER_BTN: (
      <OutlineBtn
        btnType='submit'
        text='Reset'
        handleEvent={() => {
          setSearch('');
          dispatch(toggleEmptyFilters(true));
          setSelectedFilters([]);
          setSearchValue('');
        }}
      />
    ),
  };

  useEffect(() => {
    setDisplayedPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  return (
    <div className='relative overflow-x-auto shadow-md sm:rounded-lg '>
      {paginationText && (
        <div className='flex items-center flex-wrap gap-2 md:gap-0 justify-between px-3 py-2'>
          <div className='flex items-center justify-between bg-white gap-3 flex-wrap'>
            {VIEW_TO_FILTER[view].map(
              (filter) => TYPE_TO_FILTER_COMPONENT[filter]
            )}
          </div>
          <div className='flex items-center'>
            {isApiLoading && (
              <BiLoaderCircle className='mr-2 mx-1 w-5 md:w-6 h-5 md:h-6 text-ezGreen' />
            )}
            <span className='text-gray-600 text-sm mr-2 w-24'>
              Page {pageNumber} of {displayedPageCount}
            </span>
            <button
              disabled={isApiLoading}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(1);
                }
              }}
              className={`px-2 py-2 bg-ezMidWhite rounded text-sm mx-1 ${
                isApiLoading
                  ? 'hover:cursor-not-allowed'
                  : 'hover:bg-ezLightWhite'
              }`}
            >
              {'<<'}
            </button>
            <button
              disabled={isApiLoading}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
              className={`px-2 py-2 bg-ezMidWhite rounded text-sm mx-1 ${
                isApiLoading
                  ? 'hover:cursor-not-allowed'
                  : 'hover:bg-ezLightWhite'
              }`}
            >
              {'<'}
            </button>
            <button
              disabled={isApiLoading}
              onClick={() => {
                if (pageNumber < Math.ceil(totalCount / pageCount)) {
                  setPageNumber(pageNumber + 1);
                }
              }}
              className={`px-2 py-2 bg-ezMidWhite rounded text-sm mx-1 ${
                isApiLoading
                  ? 'hover:cursor-not-allowed'
                  : 'hover:bg-ezLightWhite'
              }`}
            >
              {'>'}
            </button>
            <button
              disabled={isApiLoading}
              onClick={() => {
                if (pageNumber < Math.ceil(totalCount / pageCount)) {
                  setPageNumber(displayedPageCount);
                }
              }}
              className={`px-2 py-2 bg-ezMidWhite rounded text-sm mx-1 ${
                isApiLoading
                  ? 'hover:cursor-not-allowed'
                  : 'hover:bg-ezLightWhite'
              }`}
            >
              {'>>'}
            </button>
          </div>
        </div>
      )}
      <table
        className='w-full text-sm text-left text-gray-500 '
        {...getTableProps()}
      >
        <thead className='text-xs text-gray-700 capitalize bg-gray-50'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className='px-6 py-3' {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                className={`${
                  row.index % 2 === 0 ? 'bg-white' : 'bg-ezMidGray'
                } border-b text-ezLightBlue`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`px-6 py-4 ${
                      cell.column.id === 'status'
                        ? statusClasses[cell.value]
                        : ''
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {paginationText && (
        <div className='flex items-center flex-wrap gap-2 md:gap-0 justify-end px-3 py-2'>
          <span className='text-gray-600 text-sm'>Show</span>
          <div className='px-2'>
            <select
              value={pageCount}
              onChange={(e) => {
                setPageNumber(1);
                setPageCount(Number(e.target.value));
                setPageSize(Number(e.target.value));
              }}
              className='px-2 py-2 bg-ezMidWhite hover:bg-ezLightWhite text-ezLightBlue rounded text-sm'
            >
              {pageOptions.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
          <span className='text-gray-600 text-sm'>per page</span>
        </div>
      )}
    </div>
  );
};
