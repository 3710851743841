import React from "react";

const Search = ({searchText, onChange, value}) => {
  return (
    <div className='relative rounded'>
      <div className='absolute inset-y-0 right-1  bg-ezMidWhite focus:outline-none text-ezLightGray flex items-center pr-3 pointer-events-none'>
        <svg
          className='w-4 h-4 text-ezLightGray'
          aria-hidden='true'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 20 20'>
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
          />
        </svg>
      </div>
      <input
        type='text'
        value={value}
        id='table-search-users'
        className='block p-2 rounded text-sm text-gray-900  w-60 bg-ezMidWhite'
        placeholder={searchText} 
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default Search;
