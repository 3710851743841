import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useProtectedRoutes from 'routes/useProtectedRoutes';
import Header from './partials/header/Header';
import Sidebar from './partials/sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import {
  handlPlanTripModal,
  handlSidebar,
} from 'redux/dashbbaord/dashboardSlice';

const Layout = () => {
  const routes = useProtectedRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { auth, dashboard } = useSelector((state) => state);

  useEffect(() => {
    if (!auth?.isLogin) navigate('/login');
  }, [auth?.isLogin]);

  useEffect(() => {}, [dashboard?.isSidebar]);

  useEffect(() => {
    if (window?.innerWidth < 1025) dispatch(handlSidebar(false));
    dispatch(handlPlanTripModal(false));
  }, [location]);

  return (
    <div className='ez__DashboardLayout relative w-full z-10'>
      <Header />
      <div className='ez__LayoutDiv relative flex flex-wrap w-full h-full'>
        <div
          className={`${
            dashboard?.isSidebar ? 'block' : 'hidden'
          } lg:block ez__Sidebar w-full bg-white h-full shadow-sm border-r`}
        >
          <Sidebar />
        </div>
        <div className='ez__LayoutPages w-full bg-ezMidWhite'>
          {auth?.isLogin && routes}
        </div>
      </div>
    </div>
  );
};

export default Layout;
