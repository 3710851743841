import PrimaryBtn from 'components/btns/PrimaryBtn';
// import Spiner from "helper/Spiner";
import { USERS_COLUMNS } from 'helper/data';
import useApiHook from 'hooks/useApiHook';
import DeleteModal from 'modals/common/DeleteModal';
import AddUserModal from 'modals/user/AddNewUserModal';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BasicTable } from 'components/BasicTable/BasicTable';
import UpdateUserModal from 'modals/user/UpdateUserModal';
import { useSelector } from 'react-redux';
import OutlineBtn from 'components/btns/OutlineBtn';
import DatePicker from 'react-datepicker';
import { FaRegCalendarAlt, FaTimes } from 'react-icons/fa';
import { generateAndDownloadCSV } from 'helper/functionality';
import Spiner from 'helper/Spiner';

const Users = () => {
  const { auth } = useSelector((state) => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { handleApiCall, isApiLoading } = useApiHook();
  const [selectedUser, setSelectedUser] = useState({});
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [search, setSearch] = useState('');

  const handleExportCsv = () => {
    setIsExporting(true);

    const handleSuccess = (result) => {
      setIsExporting(false);

      if (result && result.status === 200) {
        generateAndDownloadCSV(result.data.users, startDate, endDate);
      }
    };

    const handleError = () => {
      setIsExporting(false);
    };

    handleApiCall({
      method: 'get',
      url: `/admin/user/all-csv?startDate=${startDate}&endDate=${endDate}`,
    })
      .then(handleSuccess)
      .catch(handleError);
  };

  const handleAddUser = () => {
    setModalOpen(true);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    getAllUsers();
  };

  const getAllUsers = async (startDate, endDate) => {
    const result = await handleApiCall({
      method: 'get',
      url: `/admin/user/all?page=${page}&pageSize=${pageCount}&startDate=${startDate}&endDate=${endDate}&text=${search}`,
    });

    if (result.status === 200) {
      setUsers(result?.data?.users);
      setCount(result?.data?.count);
    }
  };

  const deleteUser = async () => {
    const result = await handleApiCall({
      method: 'delete',
      url: `admin/user/delete/${selectedUser?._id}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (result?.status === 200) {
      setDeleteModalOpen(false);
      getAllUsers(startDate, endDate);
      toast.success(result?.data?.message);
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) getAllUsers(start, end);
  };

  useEffect(() => {
    getAllUsers(startDate, endDate);
  }, [page, pageCount, auth?.selectedUser]);

  useEffect(() => {
    if (search.length > 3 || search.length === 0)
      getAllUsers(startDate, endDate);
  }, [search]);

  return (
    <>
      <div className='ez__Home w-full relative z-1 p-5 pb-10'>
        <div className='flex flex-row justify-between items-center'>
          <h1 className='text-2xl font-semibold text-ezBlack'>Users</h1>
          <div className='flex flex-row'>
            <div className='ez__FormInput none flex items-center justify-between border border-ezGreen rounded-md p-3 mb-3 mr-4'>
              <DatePicker
                placeholderText='start date to end date'
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                className='w-full border-none outline-none text-ezBlack bg-transparent mr-8'
              />
              {startDate && endDate && (
                <button
                  type='button'
                  className='mr-2 text-red-500'
                  onClick={handleClearDates}
                >
                  <FaTimes />
                </button>
              )}
              <label htmlFor='startDate' className='text-ezGreen text-2xl'>
                <FaRegCalendarAlt />
              </label>
            </div>

            <OutlineBtn
              className='mr-4 none px-5 py-2 mb-3 disabled:opacity-50 disabled:cursor-not-allowed'
              btnType='submit'
              text='Export CSV'
              width
              handleEvent={handleExportCsv}
              isApiLoading={isExporting || isApiLoading}
            />
            <PrimaryBtn
              btnType='submit'
              text='Add a User'
              width
              handleEvent={handleAddUser}
            />
          </div>
        </div>
        {users.length > 0 ? (
          <div className=' w-full block card bg-white rounded-md shadow-sm border'>
            <BasicTable
              COLUMNS={USERS_COLUMNS}
              ROWS={users}
              paginationText='User'
              isApiLoading={isApiLoading}
              view='users'
              searchText='Search User'
              actions={true}
              setSelectedValue={setSelectedUser}
              setEditModalOpen={setUpdateModalOpen}
              setDeleteModalOpen={setDeleteModalOpen}
              pageNumber={page}
              setPageNumber={setPage}
              pageCount={pageCount}
              setPageCount={setPageCount}
              totalCount={count}
              setSearch={setSearch}
            />
          </div>
        ) : isApiLoading ? (
          <div className='col-span-3 block w-full h-96'>
            <Spiner color='ezGreen' />
          </div>
        ) : null}
        {modalOpen && (
          <AddUserModal
            ModalHeader='Add User'
            isViewModal={modalOpen}
            setIsViewModal={setModalOpen}
          />
        )}
      </div>
      {deleteModalOpen && (
        <DeleteModal
          isModal={deleteModalOpen}
          isLoading={isApiLoading}
          handleCloseModal={() => setDeleteModalOpen(false)}
          title={`Delete ${selectedUser?.name?.split(' ')[0]}'s account?`}
          description='Are you sure you want to delete this user? 
            Data including trips, vehicles and charging activities
            against this user will be deleted.'
          handleDelete={deleteUser}
        />
      )}
      {updateModalOpen && (
        <UpdateUserModal
          selectedUser={selectedUser}
          ModalHeader={'Update User'}
          isViewModal={updateModalOpen}
          isLoading={isApiLoading}
          setIsViewModal={setUpdateModalOpen}
        />
      )}
    </>
  );
};

export default Users;
