import Spiner from "helper/Spiner";

const PrimaryBtn = ({
  isApiLoading = false,
  btnType = "submit",
  text,
  handleEvent = () => {},
  classNames,
  width,
}) => {
  return (
    <button
      type={btnType}
      onClick={handleEvent}
      disabled={isApiLoading}
      className={`text-base  text-white border border-ezGreen bg-ezGreen rounded-md block ${
        width ? "none px-5 py-2 mb-3" : "w-full py-3 mb-5"
      } text-center ${
        isApiLoading ? "cursor-wait" : "hover:bg-transparent"
      } hover:text-ezGreen ${classNames} `}>
      {isApiLoading ? <Spiner color='white' /> : text}
    </button>
  );
};

export default PrimaryBtn;
