import React, { useEffect, useState } from 'react';
import MonthlyBarChart from 'components/BarChart';
import Card from 'components/Card';
import { chartColors, TRIPSCOLUMNS } from 'helper/data';
import { BasicTable } from 'components/BasicTable/BasicTable';
import useApiHook from '../../hooks/useApiHook';
import Spiner from 'helper/Spiner';

const Monthly = () => {
  const [activeTab, setActiveTab] = useState('daily');
  const { handleApiCall, isApiLoading } = useApiHook();
  const [data, setData] = useState({});
  const [tableData, setTableData] = useState({});

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const getAllDashboardData = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: '/admin/dashboard/data',
    });

    if (result.status === 200) {
      setData(result?.data);
    }
  };

  useEffect(() => {
    getAllDashboardData();
    getPerformanceData();
  }, []);

  const getPerformanceData = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: '/admin/dashboard/performance',
    });

    if (result.status === 200) {
      setTableData(result?.data);
    }
  };

  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10'>
      <h1 className='text-2xl font-semibold text-ezBlack my-4'>Dashboard</h1>
      <div className='grid w-full xl:grid-cols-4 gap-2 md:grid-cols-2 sm:grid-cols-1'>
        {isApiLoading ? (
          <div className='col-span-3 block w-full h-96'>
            <Spiner color='ezGreen' />
          </div>
        ) : (
          data?.stats?.map((user, index) => (
            <div key={index}>
              <Card data={user} />
            </div>
          ))
        )}
      </div>
      {!isApiLoading && (
        <div className='card w-full block bg-white rounded-md shadow-sm mt-5 border'>
          <div className='flex flex-row justify-between items-center px-6 border-solid border border-gray-100 flex-wrap'>
            <h3>Performance Insights</h3>
            <div className='border-b border-gray-200 dark:border-gray-700'>
              <ul
                className='flex flex-wrap -mb-px text-sm font-medium text-center text-ezGray  '
                id='myTab'
                data-tabs-toggle='#myTabContent'
                role='tablist'
              >
                <li className='mr-2' role='presentation'>
                  <button
                    className={`inline-block p-4 rounded-t-lg hover:text-ezBlack ${
                      activeTab === 'daily'
                        ? 'border-b border-black text-ezBlack'
                        : 'border-b border-transparent'
                    }`}
                    onClick={() => handleTabClick('daily')}
                    type='button'
                    role='tab'
                    aria-controls='daily'
                    aria-selected={activeTab === 'daily'}
                  >
                    Daily
                  </button>
                </li>
                <li className='mr-2' role='presentation'>
                  <button
                    className={`inline-block p-4 rounded-t-lg hover:text-ezBlack ${
                      activeTab === 'weekly'
                        ? 'border-b border-black text-ezBlack'
                        : 'border-b border-transparent'
                    }`}
                    onClick={() => handleTabClick('weekly')}
                    type='button'
                    role='tab'
                    aria-controls='weekly'
                    aria-selected={activeTab === 'weekly'}
                  >
                    Weekly
                  </button>
                </li>
                <li className='mr-2' role='presentation'>
                  <button
                    className={`inline-block p-4  rounded-t-lg hover:text-ezBlack  ${
                      activeTab === 'monthly'
                        ? 'border-b border-black text-ezBlack'
                        : 'border-b border-transparent'
                    }`}
                    onClick={() => handleTabClick('monthly')}
                    type='button'
                    role='tab'
                    aria-controls='monthly'
                    aria-selected={activeTab === 'monthly'}
                  >
                    Monthly
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div className='w-full py-6'>
            {tableData && (
              <MonthlyBarChart
                data={tableData[activeTab]}
                colors={chartColors}
              />
            )}
          </div>
        </div>
      )}
      {!isApiLoading && (
        <div className='mt-5 w-full block shadow-sm'>
          <h1 className='text-2xl font-medium	 text-ezBlack mb-5'>
            Recent trips
          </h1>
          {data?.recent && (
            <BasicTable COLUMNS={TRIPSCOLUMNS} ROWS={data?.recent} />
          )}
        </div>
      )}
    </div>
  );
};

export default Monthly;
