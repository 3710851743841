import { colourStyles } from "helper/helper";
import { evConnectorTypes, evNetworkTypes } from "helper/nrelData";
import Select from "react-select";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useEffect, useState } from "react";
import { handleUserVehicles } from "redux/auth/authSlice";
import { useDispatch } from "react-redux";
import AddVehicle from "modals/vehicles/AddVehicle";
import Spiner from "helper/Spiner";
import useApiHook from "hooks/useApiHook";
import GrayBtn from "components/btns/GrayBtn";
import OutlineBtn from "components/btns/OutlineBtn";

const PlanTripSteps2 = ({
  userVehicles,
  formValues,
  setFormValues,
  setSteps,
  userInfo,
  directions,
}) => {
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [userVehicle, setUserVehicle] = useState([]);
  const [vehicle, setVehicle] = useState(null);
  const [isVehicleChange, setIsVehicleChange] = useState(false);
  const [isVehicleModal, setIsVehicleModal] = useState(false);

  const valid = () => {
    if (
      formValues?.connectorType &&
      formValues?.network &&
      formValues?.vehicle &&
      formValues?.chargersType
    ) {
      return true;
    }
    return false;
  };

  // const getUserVehicles = async () => {
  //   const result = await handleApiCall({
  //     method: 'get',
  //     url: `/vehicle/${userInfo?.user?._id}`,
  //   });
  //   if (result.status === 200) {
  //     dispatch(handleUserVehicles(result?.data));
  //     setUserVehicle(result?.data);
  //     setVehicle(vehicle || result?.data[0]);
  //     setFormValues({ ...formValues, vehicle: result?.data[0] });
  //   }
  // };

  const handleStep = () => {
    // let costMin = formValues?.network === "Tesla" ? 0.4 : 0.2;
    // let costMax = formValues?.network === "Tesla" ? 0.5 : 0.3;
    // let distance = 0;
    // directions?.routes[0]?.legs?.map((leg) => {
    //   distance += leg?.distance?.value;
    // });
    // setFormValues({
    //   ...formValues,
    //   energy: `${Math.round(
    //     (formValues?.vehicle?.model?.batteryCapacity / formValues?.vehicle?.range) *
    //       (distance / 1000),
    //   )} kWh`,
    //   cost: `$${Math.round(
    //     (formValues?.vehicle?.model?.batteryCapacity / formValues?.vehicle?.range) *
    //       (distance / 1000) *
    //       costMin,
    //   )} to $${Math.round(
    //     (formValues?.vehicle?.model?.batteryCapacity / formValues?.vehicle?.range) *
    //       (distance / 1000) *
    //       costMax,
    //   )} EST`,
    // });
    setSteps(3);
  };

  // useEffect(() => {
  //   if (userInfo) getUserVehicles(userInfo?.user?._id);
  // }, []);

  return (
    <>
      <div className='bg-white w-full py-6 px-4 md:px-12'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 items-start mb-5'>
          <div className='ez__StepImg w-full block'>
            <img
              src='/assets/images/carImg.jpg'
              alt='Car Wheel'
              className='w-full h-80 object-cover md:h-auto border border-ezGreen rounded-lg'
            />
          </div>
          <div className='ez__StepForm w-full'>
            <div className='ez__CarInfo grid grid-cols-6 items-center gap-4 mb-7'>
              {vehicle ? (
                <>
                  {isVehicleChange ? (
                    <>
                      <div className='block w-full col-span-6'>
                        <p className='text-ezBlack text-sm md:text-base mb-2'>Select Vehicle*</p>
                        {userVehicle?.map((vehicleInfo) => (
                          <div key={vehicleInfo?._id} className='w-full mb-2 flex items-center'>
                            <input
                              id={vehicleInfo?._id}
                              name='notification-method'
                              type='radio'
                              defaultChecked={vehicleInfo?._id === vehicle?._id}
                              onChange={() => {
                                setVehicle(vehicleInfo);
                                setFormValues({
                                  ...formValues,
                                  vehicle: vehicleInfo,
                                });
                                setIsVehicleChange(!isVehicleChange);
                              }}
                              className='cursor-pointer h-4 w-4 border-gray-300 text-ezGreen focus:ring-ezGreen'
                            />
                            <label
                              htmlFor={vehicleInfo?._id}
                              className='cursor-pointer ml-3 block text-sm font-medium text-gray-700'>
                              {vehicleInfo?.make?.name} - {vehicleInfo?.model?.model}
                            </label>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={vehicle?.picture ? vehicle?.picture : "/assets/images/carImg.jpg"}
                        alt='Car Wheel'
                        className='w-full h-auto border border-ezGreen rounded-lg col-span-2'
                      />
                      <div className='col-span-4 w-full h-full flex flex-col justify-between'>
                        <div className='ez__info'>
                          <h3 className='text-ezBlack text-lg font-bold mb-2'>
                            <span className='mr-2'> Make:</span>
                            {vehicle?.make?.name}
                          </h3>
                          <p className='text-ezBlack text-xs block w-full mb-1'>
                            <span className='mr-2'> Model:</span>
                            {vehicle?.model?.model}
                          </p>
                          <p className='text-ezBlack text-xs block w-full mb-1'>
                            <span className='mr-2'> Range:</span>
                            {vehicle?.range}
                          </p>
                        </div>
                        {userVehicle?.length > 1 && (
                          <button
                            type='button'
                            onClick={() => setIsVehicleChange(!isVehicleChange)}
                            className='text-ezGreen text-sm sm:text-base text-right w-full'>
                            Change Vehicle
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </>
              ) : isApiLoading ? (
                <div className='col-span-6 rounded-md text-ezGreen font-semibold w-full border border-ezGreen flex items-center justify-center h-20'>
                  <Spiner className='ezGreen' />
                </div>
              ) : (
                <button
                  onClick={() => setIsVehicleModal(true)}
                  className='col-span-6 rounded-md text-ezGreen font-semibold w-full border border-ezGreen flex items-center justify-center h-20'>
                  Add Vehicle
                </button>
              )}
            </div>
            <div className='ez__FilterCard'>
              <h6 className='text-ezBlack text-base mb-3'>Filters</h6>
              <ul className='mb-6'>
                <li className='text-ezGray text-sm flex items-center'>
                  <input
                    type='checkbox'
                    name='chargres'
                    id='allChargers'
                    value='allChargers'
                    checked={formValues?.chargersType === "availableChargers" ? true : false}
                    className='w-4 h-4'
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        chargersType: e?.target?.checked ? "availableChargers" : "allChargers",
                      })
                    }
                  />
                  <label htmlFor='allChargers' className='cursor-pointer ml-2'>
                    Available Chargers
                  </label>
                </li>
              </ul>
              <div className='block relative w-full'>
                <label htmlFor='network' className='block mb-2 text-sm text-ezBlack'>
                  Network Type *
                </label>
                <div className='relative w-full text-sm sm:text-base text-ezGray'>
                  <label htmlFor='network' className='absolute right-3 top-4 cursor-pointer'>
                    <MdOutlineKeyboardArrowDown className='w-7 h-7' />
                  </label>
                  <Select
                    className='basic-single mb-4'
                    classNamePrefix='select'
                    placeholder='Select Network*'
                    isSearchable={true}
                    styles={colourStyles}
                    name='network'
                    value={
                      formValues?.network && {
                        label: evNetworkTypes?.filter(
                          (network) => network?.value === formValues.network,
                        )[0]?.title,
                        value: formValues?.network,
                      }
                    }
                    onChange={(e) => setFormValues({ ...formValues, network: e?.value })}
                    options={evNetworkTypes?.map((network) => {
                      return {
                        label: network?.title,
                        value: network?.value,
                      };
                    })}
                  />
                </div>
              </div>
              <div className='block relative w-full'>
                <label htmlFor='connectorType' className='block mb-2 text-sm text-ezBlack'>
                  Connector Type *
                </label>
                <div className='relative w-full text-sm sm:text-base text-ezGray'>
                  <label htmlFor='connectorType' className='absolute right-3 top-4 cursor-pointer'>
                    <MdOutlineKeyboardArrowDown className='w-7 h-7' />
                  </label>
                  <Select
                    className='basic-single mb-4'
                    classNamePrefix='select'
                    placeholder='Select Connector*'
                    isSearchable={true}
                    styles={colourStyles}
                    name='connectorType'
                    value={
                      formValues?.connectorType && {
                        label: evConnectorTypes?.filter(
                          (connectorType) => connectorType?.value === formValues.connectorType,
                        )[0]?.title,
                        value: formValues?.connectorType,
                      }
                    }
                    onChange={(e) => setFormValues({ ...formValues, connectorType: e?.value })}
                    options={evConnectorTypes?.map((connectorType) => {
                      return {
                        label: connectorType?.title,
                        value: connectorType?.value,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-center px-14'>
          <OutlineBtn handleEvent={() => setSteps(1)} text='Back' />
          <GrayBtn
            btnType='button'
            isDisabled={!valid()}
            classNameCondition={valid() === true}
            handleEvent={handleStep}
            text='Next Step'
          />
        </div>
      </div>
      <AddVehicle
        isVehicleModal={isVehicleModal}
        setIsVehicleModal={setIsVehicleModal}
        // getMyVehicles={getUserVehicles}
      />
    </>
  );
};

export default PlanTripSteps2;
