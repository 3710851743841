export const homeSvg = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17V9H0L10 0L20 9H17V17H3ZM10 2.69L5 7.19V15H15V7.19L10 2.69Z"
      fill="currentColor"
    />
  </svg>
);
export const organizationSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.75 4.64035L6.31997 2.45035C3.92997 1.28035 1.96997 2.47035 1.96997 5.09035V19.9303C1.96997 21.0703 2.91997 22.0003 4.07997 22.0003H11.5C12.05 22.0003 12.5 21.5503 12.5 21.0003V7.41035C12.5 6.36035 11.71 5.11035 10.75 4.64035ZM8.96997 13.7503H5.49997C5.08997 13.7503 4.74997 13.4103 4.74997 13.0003C4.74997 12.5903 5.08997 12.2503 5.49997 12.2503H8.96997C9.37997 12.2503 9.71997 12.5903 9.71997 13.0003C9.71997 13.4103 9.38997 13.7503 8.96997 13.7503ZM8.96997 9.75035H5.49997C5.08997 9.75035 4.74997 9.41035 4.74997 9.00035C4.74997 8.59035 5.08997 8.25035 5.49997 8.25035H8.96997C9.37997 8.25035 9.71997 8.59035 9.71997 9.00035C9.71997 9.41035 9.38997 9.75035 8.96997 9.75035Z" fill="#292D32" />
    <path d="M22 18.04V19.5C22 20.88 20.88 22 19.5 22H14.97C14.43 22 14 21.57 14 21.03V18.87C15.07 19 16.2 18.69 17.01 18.04C17.69 18.59 18.56 18.92 19.51 18.92C20.44 18.92 21.31 18.59 22 18.04Z" fill="#292D32" />
    <path d="M22 15.0495V15.0595C21.92 16.3695 20.85 17.4195 19.51 17.4195C18.12 17.4195 17.01 16.2895 17.01 14.9195C17.01 16.4495 15.6 17.6795 14 17.3695V11.9995C14 11.3595 14.59 10.8795 15.22 11.0195L17.01 11.4195L17.49 11.5295L19.53 11.9895C20.02 12.0895 20.47 12.2595 20.86 12.5095C20.86 12.5195 20.87 12.5195 20.87 12.5195C20.97 12.5895 21.07 12.6695 21.16 12.7595C21.62 13.2195 21.92 13.8895 21.99 14.8695C21.99 14.9295 22 14.9895 22 15.0495Z" fill="#292D32" />
  </svg>

);
export const userSvg = (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.41101 9.83843C11.0666 9.83843 13.2194 7.63603 13.2194 4.91922C13.2194 2.20241 11.0666 0 8.41101 0C5.75543 0 3.60266 2.20241 3.60266 4.91922C3.60266 7.63603 5.75543 9.83843 8.41101 9.83843Z" fill="#292D32" />
    <path d="M2.73841 19.9997C1.04149 19.9997 -0.13805 18.2345 0.516987 16.632C1.8057 13.4731 4.85542 11.2539 8.41065 11.2539C11.9659 11.2539 15.0132 13.4731 16.3043 16.632C16.9593 18.2345 15.7798 19.9997 14.0829 19.9997H2.74079H2.73841Z" fill="#292D32" />
  </svg>


);

export const tripCarSvg = (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.92 1C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.29 0.42 2.08 1L0 7V15C0 15.55 0.45 16 1 16H2C2.55 16 3 15.55 3 15V14H15V15C15 15.55 15.45 16 16 16H17C17.55 16 18 15.55 18 15V7L15.92 1ZM3.5 11C2.67 11 2 10.33 2 9.5C2 8.67 2.67 8 3.5 8C4.33 8 5 8.67 5 9.5C5 10.33 4.33 11 3.5 11ZM14.5 11C13.67 11 13 10.33 13 9.5C13 8.67 13.67 8 14.5 8C15.33 8 16 8.67 16 9.5C16 10.33 15.33 11 14.5 11ZM2 6L3.5 1.5H14.5L16 6H2ZM4 19H8V17L14 20H10V22L4 19Z"
      fill="currentColor"
    />
  </svg>
);
export const uploadSvg = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0082 12.0135H15.8659C17.5273 12.0135 18.8741 10.6666 18.8741 9.00531C18.8741 7.34385 17.6777 5.99713 16.0163 5.99713C16.0163 4.33568 14.6695 2.98895 13.0082 2.98895C12.65 2.98895 12.3111 3.0622 11.9924 3.17715C11.1652 2.15302 9.91488 1.48486 8.49589 1.48486C6.00384 1.48486 3.98362 3.50508 3.98362 5.99713C2.32228 5.99713 1.12585 7.34385 1.12585 9.00531C1.12585 10.6666 2.47269 12.0135 4.13403 12.0135H6.9918"
      stroke="#292D32"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0082 12.0135H15.8659C17.5273 12.0135 18.8741 10.6666 18.8741 9.00531C18.8741 7.34385 17.6777 5.99713 16.0163 5.99713C16.0163 4.33567 14.6695 2.98895 13.0082 2.98895C12.65 2.98895 12.3111 3.0622 11.9924 3.17715C11.1652 2.15302 9.91488 1.48486 8.49589 1.48486C6.00384 1.48486 3.98362 3.50508 3.98362 5.99713C2.32228 5.99713 1.12585 7.34385 1.12585 9.00531C1.12585 10.6666 2.47269 12.0135 4.13403 12.0135H6.9918M9.99998 16.5151V7.50141"
      stroke="#292D32"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.7439 9.44559L9.46826 7.72123C9.76193 7.42756 10.2381 7.42756 10.5318 7.72123L12.2562 9.44559"
      stroke="#292D32"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const locationSvg = (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z"
      fill="currentColor"
    />
  </svg>
);
export const locationOutlinedSvg = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 7.025C10.9472 7.025 11.8556 7.40692 12.5254 8.08674C13.1952 8.76656 13.5714 9.68859 13.5714 10.65C13.5714 11.126 13.4791 11.5974 13.2996 12.0372C13.1201 12.477 12.857 12.8766 12.5254 13.2133C12.1937 13.5499 11.8 13.8169 11.3667 13.9991C10.9334 14.1812 10.469 14.275 10 14.275C9.0528 14.275 8.14439 13.8931 7.47462 13.2133C6.80485 12.5334 6.42857 11.6114 6.42857 10.65C6.42857 9.68859 6.80485 8.76656 7.47462 8.08674C8.14439 7.40692 9.0528 7.025 10 7.025ZM10 0.5C12.6522 0.5 15.1957 1.56937 17.0711 3.47287C18.9464 5.37636 20 7.95805 20 10.65C20 18.2625 10 29.5 10 29.5C10 29.5 0 18.2625 0 10.65C0 7.95805 1.05357 5.37636 2.92893 3.47287C4.8043 1.56937 7.34784 0.5 10 0.5ZM10 3.4C8.1056 3.4 6.28878 4.16384 4.94924 5.52348C3.60969 6.88311 2.85714 8.72718 2.85714 10.65C2.85714 12.1 2.85714 15 10 24.7295C17.1429 15 17.1429 12.1 17.1429 10.65C17.1429 8.72718 16.3903 6.88311 15.0508 5.52348C13.7112 4.16384 11.8944 3.4 10 3.4Z"
      fill="#1F1F1F"
    />
  </svg>
);
export const CalenderSvg = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9.5H4V11.5H6V9.5ZM10 9.5H8V11.5H10V9.5ZM14 9.5H12V11.5H14V9.5ZM16 2.5H15V0.5H13V2.5H5V0.5H3V2.5H2C0.89 2.5 0 3.4 0 4.5V18.5C0 19.0304 0.210714 19.5391 0.585786 19.9142C0.960859 20.2893 1.46957 20.5 2 20.5H16C16.5304 20.5 17.0391 20.2893 17.4142 19.9142C17.7893 19.5391 18 19.0304 18 18.5V4.5C18 3.96957 17.7893 3.46086 17.4142 3.08579C17.0391 2.71071 16.5304 2.5 16 2.5ZM16 18.5H2V7.5H16V18.5Z"
      fill="#1F1F1F"
    />
  </svg>
);

export const info = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2C14.4 2 18 5.6 18 10C18 14.4 14.4 18 10 18C5.6 18 2 14.4 2 10C2 5.6 5.6 2 10 2ZM10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM10 3C8.6 3 7.5 4.1 7.5 5.5C7.5 6.9 8.6 8 10 8C11.4 8 12.5 6.9 12.5 5.5C12.5 4.1 11.4 3 10 3ZM11.5 5.5C11.5 5.7 11.4 5.9 11.3 6.1L10.7 5.5L11.3 4.9C11.4 5.1 11.5 5.3 11.5 5.5ZM10.6 4.2L10 4.8L9.4 4.2C9.6 4.1 9.8 4 10 4C10.2 4 10.4 4.1 10.6 4.2ZM8.7 6.1C8.6 5.9 8.5 5.7 8.5 5.5C8.5 5.3 8.6 5.1 8.7 4.9L9.3 5.5L8.7 6.1ZM9.4 6.8L10 6.2L10.6 6.8C10.4 6.9 10.2 7 10 7C9.8 7 9.6 6.9 9.4 6.8ZM10 12C8.6 12 7.5 13.1 7.5 14.5C7.5 15.9 8.6 17 10 17C11.4 17 12.5 15.9 12.5 14.5C12.5 13.1 11.4 12 10 12ZM11.5 14.5C11.5 14.7 11.4 14.9 11.3 15.1L10.7 14.5L11.3 13.9C11.4 14.1 11.5 14.3 11.5 14.5ZM10.6 13.2L10 13.8L9.4 13.2C9.6 13.1 9.8 13 10 13C10.2 13 10.4 13.1 10.6 13.2ZM8.7 15.1C8.6 14.9 8.5 14.7 8.5 14.5C8.5 14.3 8.6 14.1 8.7 13.9L9.3 14.5L8.7 15.1ZM9.4 15.8L10 15.2L10.6 15.8C10.4 15.9 10.2 16 10 16C9.8 16 9.6 15.9 9.4 15.8Z"
      fill="currentColor"
    />
  </svg>
);

export const searchVehicle = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.61 11.11C6.61 9.03 7.59 7.19 9.1 6H2L3.5 1.5H14.5L15.72 5.16C16.56 5.53 17.3 6.07 17.91 6.74L15.92 1C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.28 0.42 2.08 1L0 7V15C0 15.55 0.45 16 1 16H2C2.55 16 3 15.55 3 15V14H7.29C6.86 13.13 6.61 12.15 6.61 11.11ZM3.5 11C2.67 11 2 10.33 2 9.5C2 8.67 2.67 8 3.5 8C4.33 8 5 8.67 5 9.5C5 10.33 4.33 11 3.5 11ZM17.71 15.7L17.7 15.71L17.71 15.7ZM13.11 6.61C15.61 6.61 17.61 8.61 17.61 11.11C17.61 12 17.36 12.82 16.92 13.5L20 16.61L18.61 18L15.5 14.93C14.8 15.36 14 15.61 13.11 15.61C10.61 15.61 8.61 13.61 8.61 11.11C8.61 8.61 10.61 6.61 13.11 6.61ZM13.11 8.61C11.73 8.61 10.61 9.73 10.61 11.11C10.61 12.49 11.73 13.61 13.11 13.61C14.49 13.61 15.61 12.5 15.61 11.11C15.61 9.72 14.5 8.61 13.11 8.61Z"
      fill="currentColor"
    />
  </svg>
);

export const chargingSvg = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.77 4.23L15.78 4.22L12.06 0.5L11 1.56L13.11 3.67C12.17 4.03 11.5 4.93 11.5 6C11.5 6.3283 11.5647 6.65339 11.6903 6.95671C11.8159 7.26002 12.0001 7.53562 12.2322 7.76777C12.7011 8.23661 13.337 8.5 14 8.5C14.36 8.5 14.69 8.42 15 8.29V15.5C15 15.7652 14.8946 16.0196 14.7071 16.2071C14.5196 16.3946 14.2652 16.5 14 16.5C13.7348 16.5 13.4804 16.3946 13.2929 16.2071C13.1054 16.0196 13 15.7652 13 15.5V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H10V2C10 1.46957 9.78929 0.960859 9.41421 0.585786C9.03914 0.210714 8.53043 0 8 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18H10V10.5H11.5V15.5C11.5 16.163 11.7634 16.7989 12.2322 17.2678C12.7011 17.7366 13.337 18 14 18C14.3283 18 14.6534 17.9353 14.9567 17.8097C15.26 17.6841 15.5356 17.4999 15.7678 17.2678C15.9999 17.0356 16.1841 16.76 16.3097 16.4567C16.4353 16.1534 16.5 15.8283 16.5 15.5V6C16.5 5.31 16.22 4.68 15.77 4.23ZM14 7C13.7348 7 13.4804 6.89464 13.2929 6.70711C13.1054 6.51957 13 6.26522 13 6C13 5.73478 13.1054 5.48043 13.2929 5.29289C13.4804 5.10536 13.7348 5 14 5C14.2652 5 14.5196 5.10536 14.7071 5.29289C14.8946 5.48043 15 5.73478 15 6C15 6.26522 14.8946 6.51957 14.7071 6.70711C14.5196 6.89464 14.2652 7 14 7ZM4 15V10.5H2L6 3V8H8L4 15Z"
      fill="currentColor"
    />
  </svg>
);

export const loudMicrophoneSvg = (
  <svg 
    width="17"
    height="18"
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M15.7492 4.13828L17.0044 2.88309C17.2333 2.65426 17.2333 2.28328 17.0044 2.05445C16.7756 1.82563 16.4046 1.82566 16.1758 2.05445L14.9206 3.30965C14.6917 3.53844 14.6917 3.90945 14.9206 4.13828C15.1493 4.36707 15.5203 4.36715 15.7492 4.13828ZM18.4729 6.90363H17.2177C16.8941 6.90363 16.6318 7.16598 16.6318 7.48957C16.6318 7.81316 16.8941 8.07551 17.2177 8.07551H18.4729C18.7965 8.07551 19.0589 7.81316 19.0589 7.48957C19.0589 7.16598 18.7965 6.90363 18.4729 6.90363ZM11.5693 2.42707C11.8929 2.42707 12.1552 2.16473 12.1552 1.84113V0.585938C12.1552 0.262344 11.8929 0 11.5693 0C11.2457 0 10.9833 0.262344 10.9833 0.585938V1.84113C10.9833 2.16473 11.2457 2.42707 11.5693 2.42707ZM1.11326 14.6065C0.884626 14.8351 0.884431 15.2064 1.11326 15.4352L3.62369 17.9456C3.85244 18.1744 4.22345 18.1745 4.45232 17.9456L5.4342 16.9638L2.09517 13.6247L1.11326 14.6065ZM11.2579 15.0796L7.14353 17.0158L9.95615 19.8284C10.1849 20.0572 10.556 20.0573 10.7848 19.8284L12.9814 17.6318C13.2102 17.403 13.2102 17.032 12.9814 16.8032L11.2579 15.0796ZM8.84556 2.05445C8.77908 1.98797 8.69757 1.93846 8.60793 1.91011C8.51828 1.88176 8.42313 1.8754 8.3305 1.89156C8.23788 1.90773 8.15051 1.94595 8.07577 2.00299C8.00102 2.06003 7.9411 2.13422 7.90107 2.2193L2.92377 12.796L6.26279 16.1351L16.8395 11.1578C16.9246 11.1177 16.9988 11.0578 17.0558 10.9831C17.1129 10.9083 17.1511 10.821 17.1673 10.7283C17.1834 10.6357 17.1771 10.5406 17.1487 10.4509C17.1204 10.3613 17.0709 10.2798 17.0044 10.2133L8.84556 2.05445Z"
      fill="#292D32"
    />
  </svg>
);

export const LargeMicrophoneSvg = (
  <svg 
    width="156"
    height="156"
    viewBox="0 0 20 20" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M15.7492 4.13828L17.0044 2.88309C17.2333 2.65426 17.2333 2.28328 17.0044 2.05445C16.7756 1.82563 16.4046 1.82566 16.1758 2.05445L14.9206 3.30965C14.6917 3.53844 14.6917 3.90945 14.9206 4.13828C15.1493 4.36707 15.5203 4.36715 15.7492 4.13828ZM18.4729 6.90363H17.2177C16.8941 6.90363 16.6318 7.16598 16.6318 7.48957C16.6318 7.81316 16.8941 8.07551 17.2177 8.07551H18.4729C18.7965 8.07551 19.0589 7.81316 19.0589 7.48957C19.0589 7.16598 18.7965 6.90363 18.4729 6.90363ZM11.5693 2.42707C11.8929 2.42707 12.1552 2.16473 12.1552 1.84113V0.585938C12.1552 0.262344 11.8929 0 11.5693 0C11.2457 0 10.9833 0.262344 10.9833 0.585938V1.84113C10.9833 2.16473 11.2457 2.42707 11.5693 2.42707ZM1.11326 14.6065C0.884626 14.8351 0.884431 15.2064 1.11326 15.4352L3.62369 17.9456C3.85244 18.1744 4.22345 18.1745 4.45232 17.9456L5.4342 16.9638L2.09517 13.6247L1.11326 14.6065ZM11.2579 15.0796L7.14353 17.0158L9.95615 19.8284C10.1849 20.0572 10.556 20.0573 10.7848 19.8284L12.9814 17.6318C13.2102 17.403 13.2102 17.032 12.9814 16.8032L11.2579 15.0796ZM8.84556 2.05445C8.77908 1.98797 8.69757 1.93846 8.60793 1.91011C8.51828 1.88176 8.42313 1.8754 8.3305 1.89156C8.23788 1.90773 8.15051 1.94595 8.07577 2.00299C8.00102 2.06003 7.9411 2.13422 7.90107 2.2193L2.92377 12.796L6.26279 16.1351L16.8395 11.1578C16.9246 11.1177 16.9988 11.0578 17.0558 10.9831C17.1129 10.9083 17.1511 10.821 17.1673 10.7283C17.1834 10.6357 17.1771 10.5406 17.1487 10.4509C17.1204 10.3613 17.0709 10.2798 17.0044 10.2133L8.84556 2.05445Z"
      fill="#292d3261"
    />
  </svg>
);

export const displayTabOne = (
  <svg width="79" height="62" viewBox="0 0 79 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <rect x="0.795853" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
      <rect x="0.795853" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
      <rect x="10.7992" y="50.0977" width="57.9777" height="11.0239" fill="#808080"/>
    </g>
  </svg>
);

export const activeDisplayTabOne = (
  <svg width="79" height="62" viewBox="0 0 79 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.795853" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
    <rect x="0.795853" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
    <rect x="10.7992" y="50.0977" width="57.9777" height="11.0239" fill="#228B22"/>
  </svg>
);

export const displayTabTwo = (
  <svg width="79" height="62" viewBox="0 0 78 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.212113" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
    <rect x="0.212113" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
    <rect x="10.2153" y="9.57422" width="57.9777" height="11.0239" fill="#EBEBEB"/>
    <rect x="10.2153" y="25.498" width="57.9777" height="11.0239" fill="#808080"/>
    <rect x="10.2153" y="41.4209" width="57.9777" height="11.0239" fill="#EBEBEB"/>
  </svg>
);

export const activeDisplayTabTwo = (
  <svg width="79" height="62" viewBox="0 0 78 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.212113" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
    <rect x="0.212113" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
    <rect x="10.2153" y="9.57422" width="57.9777" height="11.0239" fill="#EBEBEB"/>
    <rect x="10.2153" y="25.498" width="57.9777" height="11.0239" fill="#228B22"/>
    <rect x="10.2153" y="41.4209" width="57.9777" height="11.0239" fill="#EBEBEB"/>
  </svg>
);

export const displayTabThree = (
  <svg width="79" height="62" viewBox="0 0 79 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.628373" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
    <rect x="0.628373" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
    <rect x="66.1594" y="13.6572" width="11.8405" height="35.5215" fill="#808080"/>
    <rect x="10.6317" y="7.53223" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    <rect x="10.6318" y="15.6982" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    <rect x="10.6317" y="23.8643" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    <rect x="10.6317" y="32.0303" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    <rect x="10.6318" y="40.1963" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    <rect x="10.6317" y="48.3613" width="49.8118" height="6.53269" fill="#EBEBEB"/>
  </svg>
);

export const activeDisplayTabThree = (
  <svg width="79" height="62" viewBox="0 0 79 62" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.628373" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" fill="white"/>
      <rect x="0.628373" y="0.795853" width="77.5757" height="60.8357" rx="3.47049" stroke="#D9D9D9" stroke-width="0.408293"/>
      <rect x="66.1594" y="13.6572" width="11.8405" height="35.5215" fill="#228B22"/>
      <rect x="10.6317" y="7.53223" width="49.8118" height="6.53269" fill="#EBEBEB"/>
      <rect x="10.6318" y="15.6982" width="49.8118" height="6.53269" fill="#EBEBEB"/>
      <rect x="10.6317" y="23.8643" width="49.8118" height="6.53269" fill="#EBEBEB"/>
      <rect x="10.6317" y="32.0303" width="49.8118" height="6.53269" fill="#EBEBEB"/>
      <rect x="10.6318" y="40.1963" width="49.8118" height="6.53269" fill="#EBEBEB"/>
      <rect x="10.6317" y="48.3613" width="49.8118" height="6.53269" fill="#EBEBEB"/>
    </svg>
  );

export const arrowDownSvg = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.62 5.2207L7.81667 9.02404C7.3675 9.4732 6.6325 9.4732 6.18334 9.02404L2.38 5.2207"
      stroke="#292D32"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const leatherboard = (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1 9.65045V3.81818C17.1 2.76818 16.2545 1.90909 15.2 1.90909H11.229C10.83 0.801818 9.785 0 8.55 0C7.315 0 6.27 0.801818 5.871 1.90909H1.9C0.855 1.90909 0 2.76818 0 3.81818V17.1818C0 18.2414 0.855 19.0909 1.9 19.0909H7.7045C8.9015 20.2745 10.5355 21 12.35 21C16.0265 21 19 18.0123 19 14.3182C19 12.495 18.278 10.8532 17.1 9.65045ZM8.55 1.90909C9.0725 1.90909 9.5 2.33864 9.5 2.86364C9.5 3.38864 9.0725 3.81818 8.55 3.81818C8.0275 3.81818 7.6 3.38864 7.6 2.86364C7.6 2.33864 8.0275 1.90909 8.55 1.90909ZM1.9 17.1818V3.81818H3.8V5.72727H13.3V3.81818H15.2V8.28545C14.3355 7.875 13.376 7.63636 12.35 7.63636C8.6735 7.63636 5.7 10.6241 5.7 14.3182C5.7 15.3491 5.9375 16.3132 6.346 17.1818H1.9ZM12.35 19.0909C9.728 19.0909 7.6 16.9527 7.6 14.3182C7.6 11.6836 9.728 9.54545 12.35 9.54545C14.972 9.54545 17.1 11.6836 17.1 14.3182C17.1 16.9527 14.972 19.0909 12.35 19.0909ZM12.825 14.5568L15.542 16.17L14.8295 17.3345L11.4 15.2727V10.5H12.825V14.5568Z"
      fill="currentColor"
    />
  </svg>
);

export const homeChargres = (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 11H17V14H20V16H17V19H15V16H12V14H15V11ZM10 0L20 9H16C12.69 9 10 11.69 10 15C10 15.7 10.12 16.37 10.34 17H3V9H0L10 0Z"
      fill="currentColor"
    />
  </svg>
);

export const store = (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0597 0V3.63636H6.26866L9.85075 7.27273L13.4328 3.63636H11.6418V0M0 1.81818V3.63636H1.79104L5.01493 10.5455L3.76119 12.7273C3.67164 13 3.58209 13.2727 3.58209 13.6364C3.58209 14.6364 4.38806 15.4545 5.37313 15.4545H16.1194V13.6364H5.73134C5.64179 13.6364 5.55224 13.5455 5.55224 13.4545V13.3636L6.35821 11.8182H12.9851C13.6119 11.8182 14.2388 11.4545 14.5075 10.9091L18 4.54545L16.4776 3.63636L12.9851 10H6.71642L2.95522 1.81818M5.37313 16.3636C4.38806 16.3636 3.58209 17.1818 3.58209 18.1818C3.58209 19.1818 4.38806 20 5.37313 20C6.35821 20 7.16418 19.1818 7.16418 18.1818C7.16418 17.1818 6.35821 16.3636 5.37313 16.3636ZM14.3284 16.3636C13.3433 16.3636 12.5373 17.1818 12.5373 18.1818C12.5373 19.1818 13.3433 20 14.3284 20C15.3134 20 16.1194 19.1818 16.1194 18.1818C16.1194 17.1818 15.3134 16.3636 14.3284 16.3636Z"
      fill="currentColor"
    />
  </svg>
);

export const setting = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72933 13.5C8.80107 13.5 7.91084 13.1313 7.25446 12.4749C6.59808 11.8185 6.22933 10.9283 6.22933 10C6.22933 9.07174 6.59808 8.1815 7.25446 7.52513C7.91084 6.86875 8.80107 6.5 9.72933 6.5C10.6576 6.5 11.5478 6.86875 12.2042 7.52513C12.8606 8.1815 13.2293 9.07174 13.2293 10C13.2293 10.9283 12.8606 11.8185 12.2042 12.4749C11.5478 13.1313 10.6576 13.5 9.72933 13.5ZM17.1593 10.97C17.1993 10.65 17.2293 10.33 17.2293 10C17.2293 9.67 17.1993 9.34 17.1593 9L19.2693 7.37C19.4593 7.22 19.5093 6.95 19.3893 6.73L17.3893 3.27C17.2693 3.05 16.9993 2.96 16.7793 3.05L14.2893 4.05C13.7693 3.66 13.2293 3.32 12.5993 3.07L12.2293 0.42C12.1893 0.18 11.9793 0 11.7293 0H7.72933C7.47933 0 7.26933 0.18 7.22933 0.42L6.85933 3.07C6.22933 3.32 5.68933 3.66 5.16933 4.05L2.67933 3.05C2.45933 2.96 2.18933 3.05 2.06933 3.27L0.0693316 6.73C-0.0606684 6.95 -0.000668302 7.22 0.189332 7.37L2.29933 9C2.25933 9.34 2.22933 9.67 2.22933 10C2.22933 10.33 2.25933 10.65 2.29933 10.97L0.189332 12.63C-0.000668302 12.78 -0.0606684 13.05 0.0693316 13.27L2.06933 16.73C2.18933 16.95 2.45933 17.03 2.67933 16.95L5.16933 15.94C5.68933 16.34 6.22933 16.68 6.85933 16.93L7.22933 19.58C7.26933 19.82 7.47933 20 7.72933 20H11.7293C11.9793 20 12.1893 19.82 12.2293 19.58L12.5993 16.93C13.2293 16.67 13.7693 16.34 14.2893 15.94L16.7793 16.95C16.9993 17.03 17.2693 16.95 17.3893 16.73L19.3893 13.27C19.5093 13.05 19.4593 12.78 19.2693 12.63L17.1593 10.97Z"
      fill="currentColor"
    />
  </svg>
);

export const privacyPolicy = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-1 6h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
  </svg>
);

export const termsAndConditions = (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="1.5"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
    ></path>
  </svg>
);

export const infoAbout = (
  <svg
    width="7"
    height="17"
    viewBox="0 0 7 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.49988 0C5.10206 0 4.72053 0.158035 4.43922 0.43934C4.15792 0.720644 3.99988 1.10218 3.99988 1.5C3.99988 1.89782 4.15792 2.27936 4.43922 2.56066C4.72053 2.84196 5.10206 3 5.49988 3C5.89771 3 6.27924 2.84196 6.56054 2.56066C6.84185 2.27936 6.99988 1.89782 6.99988 1.5C6.99988 1.10218 6.84185 0.720644 6.56054 0.43934C6.27924 0.158035 5.89771 0 5.49988 0ZM5.13988 4.77C3.94988 4.87 0.699882 7.46 0.699882 7.46C0.499882 7.61 0.559883 7.6 0.719883 7.88C0.879883 8.15 0.859883 8.17 1.04988 8.04C1.24988 7.91 1.57988 7.7 2.12988 7.36C4.24988 6 2.46988 9.14 1.55988 14.43C1.19988 17.05 3.55988 15.7 4.16988 15.3C4.76988 14.91 6.37988 13.8 6.53988 13.69C6.75988 13.54 6.59988 13.42 6.42988 13.17C6.30988 13 6.18988 13.12 6.18988 13.12C5.53988 13.55 4.34988 14.45 4.18988 13.88C3.99988 13.31 5.21988 9.4 5.88988 6.71C5.99988 6.07 6.29988 4.67 5.13988 4.77Z"
      fill="currentColor"
    />
  </svg>
);

export const faqIcon = (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"></path>
  </svg>
);

export const contactUsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currrentColor"
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
  >
    <g id="_x32_5_attachment" />

    <g id="_x32_4_office" />

    <g id="_x32_3_pin" />

    <g id="_x32_2_business_card" />

    <g id="_x32_1_form" />

    <g id="_x32_0_headset" />

    <g id="_x31_9_video_call" />

    <g id="_x31_8_letter_box" />

    <g id="_x31_7_papperplane" />

    <g id="_x31_6_laptop" />

    <g id="_x31_5_connection" />

    <g id="_x31_4_phonebook" />

    <g id="_x31_3_classic_telephone" />

    <g id="_x31_2_sending_mail" />

    <g id="_x31_1_man_talking" />

    <g id="_x31_0_date" />

    <g id="_x30_9_review" />

    <g id="_x30_8_email" />

    <g id="_x30_7_information" />

    <g id="_x30_6_phone_talking">
      <g>
        <g>
          <path d="M37.063,18.062h-0.0596c-0.5522,0-0.9702,0.4478-0.9702,1s0.4775,1,1.0298,1s1-0.4478,1-1S37.6152,18.062,37.063,18.062z     " />

          <path d="M45.1787,18.062H45.123c-0.5522,0-0.9722,0.4478-0.9722,1s0.4756,1,1.0278,1s1-0.4478,1-1S45.731,18.062,45.1787,18.062z     " />

          <path d="M53.2983,18.062h-0.0596c-0.5522,0-0.9702,0.4478-0.9702,1s0.4775,1,1.0298,1s1-0.4478,1-1     S53.8506,18.062,53.2983,18.062z" />

          <path d="M45.1953,45.9268c-5.1489-2.9038-6.6909-2.6665-10.6172-0.4468c-2.0146,1.3389-4.4404,0.5225-8.6563-2.9111     c-0.8276-0.6743-1.6592-1.4263-2.4688-2.2319c-0.8091-0.8125-1.5605-1.644-2.2344-2.4722     c-3.1782-3.8999-4.0435-7.459-3.0112-8.5317c3.042-3.271,2.3516-5.957-0.3335-10.7173c-1.6172-3.0591-3.3931-6.104-5.7568-6.8027     c-1.7139-0.5034-4.2588,0.8154-5.0166,1.3184c-1.9492,1.2983-3.8003,3.5947-4.8311,5.9937     c-1.896,4.4136-1.3931,9.7329-0.29,13.2397c1.812,5.749,6.1611,12.4063,11.6348,17.8086     c5.4043,5.4761,12.0615,9.8242,17.8081,11.6313c1.8154,0.5728,4.1167,0.9844,6.5283,0.9844c2.2437,0,4.583-0.3564,6.7124-1.271     c2.3989-1.0327,4.6938-2.8838,5.9888-4.8306c0.5039-0.7554,1.8276-3.2998,1.3184-5.021     C51.2754,49.3071,48.2305,47.5308,45.1953,45.9268z M44.2368,47.6821c1.8521,0.979,5.2998,2.8018,5.8149,4.5513     c0.1056,0.3564-0.0228,1.0059-0.2598,1.681l-13.5292-7.089C38.8073,45.4165,39.8377,45.2009,44.2368,47.6821z M11.5513,13.7314     c1.7524,0.5181,3.5752,3.9663,4.5674,5.8428c2.6213,4.647,2.613,6.1134,0.9274,8.0579L9.748,14.0356     c0.556-0.2056,1.1049-0.3412,1.499-0.3412C11.3633,13.6943,11.4658,13.7061,11.5513,13.7314z M43.873,59.6807     c-3.9175,1.6836-8.8311,1.1694-11.8501,0.2163c-5.4517-1.7144-11.8032-5.8765-16.9897-11.1328     c-0.0034-0.0034-0.0063-0.0063-0.0098-0.0098C9.7695,43.5698,5.606,37.2178,3.8872,31.7642     c-0.9497-3.0195-1.4619-7.9346,0.2202-11.8501c0.8441-1.9645,2.3123-3.8291,3.8699-4.948l7.923,14.7618     c-0.4362,2.3732,0.9189,5.9038,3.7676,9.4001c0.7153,0.8789,1.5122,1.7607,2.3711,2.623     c0.8594,0.856,1.7407,1.6528,2.6196,2.3687c3.0879,2.5153,6.3303,4.6262,9.3667,3.7915l14.8708,7.792     C47.7888,57.3002,45.8823,58.816,43.873,59.6807z" />

          <path d="M60.9551,10.771C56.3843,2.0591,45.5757-1.3105,36.8604,3.2568l-0.0005,0.0005     c-8.7119,4.5723-12.0825,15.3813-7.5137,24.0952c0.3311,0.6313,0.709,1.2549,1.1274,1.8613l-2.7012,4.6299     c-0.1885,0.3228-0.1812,0.7241,0.0195,1.0396c0.1997,0.3159,0.5596,0.4912,0.9321,0.4604l7.75-0.6851     c2.7095,1.5068,5.6899,2.2627,8.6748,2.2627c2.8374,0,5.6787-0.6836,8.293-2.0552     C62.1543,30.2944,65.5249,19.4854,60.9551,10.771z M52.5127,33.0952c-4.8472,2.543-10.5723,2.4214-15.3154-0.3252     c-0.1523-0.0884-0.3257-0.1348-0.501-0.1348c-0.0293,0-0.0586,0.0015-0.0879,0.0039l-6.1338,0.542l2.0532-3.519     c0.2017-0.3462,0.1777-0.7793-0.0615-1.1006c-0.5132-0.6899-0.9668-1.4092-1.3486-2.1377     c-4.0571-7.7373-1.0645-17.3354,6.6719-21.396l-0.0005,0.0005c7.7378-4.0581,17.3354-1.0635,21.395,6.6719     C63.2417,19.438,60.2485,29.0356,52.5127,33.0952z" />
        </g>
      </g>
    </g>

    <g id="_x30_5_women_talking" />

    <g id="_x30_4_calling" />

    <g id="_x30_3_women" />

    <g id="_x30_2_writing" />

    <g id="_x30_1_chatting" />
  </svg>
);

export const milesSvg = (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.22936 5.19322C3.39288 5.19322 2.71619 4.58125 2.71619 3.82479C2.71619 3.46186 2.87561 3.1138 3.15938 2.85717C3.44316 2.60054 3.82804 2.45637 4.22936 2.45637C5.06583 2.45637 5.74253 3.06833 5.74253 3.82479C5.74253 4.18772 5.5831 4.53579 5.29933 4.79242C5.01556 5.04904 4.63067 5.19322 4.22936 5.19322ZM4.22936 0C1.87971 0 0 1.69991 0 3.82479C0 6.68914 4.22936 10.9304 4.22936 10.9304C4.22936 10.9304 8.45871 6.68914 8.45871 3.82479C8.45871 1.69991 6.579 0 4.22936 0ZM14.5678 5.19322C14.1665 5.19322 13.7816 5.04904 13.4978 4.79242C13.214 4.53579 13.0546 4.18772 13.0546 3.82479C13.0546 3.06833 13.7313 2.45637 14.5678 2.45637C15.4043 2.45637 16.081 3.06833 16.081 3.82479C16.081 4.18772 15.9215 4.53579 15.6378 4.79242C15.354 5.04904 14.9691 5.19322 14.5678 5.19322ZM14.5678 0C12.2181 0 10.3384 1.69991 10.3384 3.82479C10.3384 6.68914 14.5678 10.9304 14.5678 10.9304C14.5678 10.9304 18.7971 6.68914 18.7971 3.82479C18.7971 1.69991 16.9174 0 14.5678 0ZM14.5678 11.8994C13.3742 11.8994 12.3121 12.5793 11.9174 13.5993H6.87975C6.36283 12.2733 4.74628 11.5764 3.2895 12.0438C1.81392 12.5113 1.04324 13.9732 1.56016 15.2992C2.08648 16.6251 3.69364 17.3221 5.16921 16.8546C5.96809 16.5996 6.579 16.0216 6.87975 15.2992H11.9268C12.4437 16.6251 14.0603 17.3221 15.5076 16.8546C16.9926 16.3871 17.7633 14.9252 17.2464 13.5993C16.8422 12.5793 15.7708 11.8994 14.5678 11.8994ZM14.5678 15.7241C14.1939 15.7241 13.8353 15.5898 13.5709 15.3507C13.3065 15.1116 13.158 14.7873 13.158 14.4492C13.158 14.1111 13.3065 13.7868 13.5709 13.5477C13.8353 13.3086 14.1939 13.1743 14.5678 13.1743C14.9417 13.1743 15.3003 13.3086 15.5647 13.5477C15.829 13.7868 15.9776 14.1111 15.9776 14.4492C15.9776 14.7873 15.829 15.1116 15.5647 15.3507C15.3003 15.5898 14.9417 15.7241 14.5678 15.7241Z"
      fill="currentColor"
    />
  </svg>
);

export const clockSvg = (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.89027 15.3C14.0256 15.3 17.4091 12.24 17.4091 8.5C17.4091 4.76 14.0256 1.7 9.89027 1.7C5.7549 1.7 2.37141 4.76 2.37141 8.5C2.37141 12.24 5.7549 15.3 9.89027 15.3ZM9.89027 0C15.0595 0 19.2888 3.825 19.2888 8.5C19.2888 13.175 15.0595 17 9.89027 17C4.72106 17 0.491699 13.175 0.491699 8.5C0.491699 3.825 4.72106 0 9.89027 0ZM14.5896 10.115L13.9317 11.22L8.95041 8.755V4.25H10.3602V7.99L14.5896 10.115Z"
      fill="currentColor"
    />
  </svg>
);

export const speedSvg = (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1788 11.2368C9.43105 11.2368 8.71388 10.9636 8.18511 10.4773C7.65633 9.99096 7.35927 9.33139 7.35927 8.64366C7.35927 7.67557 7.93259 6.82849 8.76906 6.38766L17.8951 1.52993L12.6977 9.81055C12.2277 10.6576 11.2785 11.2368 10.1788 11.2368ZM10.1788 0C11.88 0 13.4683 0.432183 14.8499 1.14096L12.8762 2.18685C12.0586 1.89296 11.1187 1.72873 10.1788 1.72873C8.18472 1.72873 6.27227 2.45727 4.86221 3.75407C3.45215 5.05087 2.65999 6.80971 2.65999 8.64366C2.65999 10.5539 3.49646 12.2826 4.85925 13.5273H4.86865C5.2352 13.8644 5.2352 14.409 4.86865 14.7461C4.50211 15.0832 3.9006 15.0832 3.53405 14.7547C1.83291 13.1902 0.780273 11.0293 0.780273 8.64366C0.780273 6.35122 1.77048 4.15267 3.53305 2.53167C5.29563 0.910669 7.68619 0 10.1788 0ZM19.5774 8.64366C19.5774 11.0293 18.5248 13.1902 16.8236 14.7547C16.4571 15.0832 15.865 15.0832 15.4984 14.7461C15.1319 14.409 15.1319 13.8644 15.4984 13.5273C16.8612 12.274 17.6977 10.5539 17.6977 8.64366C17.6977 7.77929 17.5191 6.91493 17.1902 6.137L18.3274 4.32183C19.1075 5.61838 19.5774 7.07051 19.5774 8.64366Z"
      fill="currentColor"
    />
  </svg>
);

export const energySvg = (
  <svg
    width="13"
    height="19"
    viewBox="0 0 13 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75389 7.315V8.63636H9.56492L7.78103 11.685V10.3636H3.97001L5.75389 7.315ZM7.78103 0L0.686035 12.0909H5.75389V19L12.8489 6.90909H7.78103V0Z"
      fill="currentColor"
    />
  </svg>
);

export const dollorSvg = (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.851562 10.6667H2.62071C2.62071 11.6267 3.83257 12.4444 5.27442 12.4444C6.71627 12.4444 7.92813 11.6267 7.92813 10.6667C7.92813 9.68889 7.00818 9.33333 5.06212 8.86222C3.18683 8.39111 0.851562 7.80444 0.851562 5.33333C0.851562 3.74222 2.15188 2.39111 3.94756 1.93778V0H6.60128V1.93778C8.39696 2.39111 9.69728 3.74222 9.69728 5.33333H7.92813C7.92813 4.37333 6.71627 3.55556 5.27442 3.55556C3.83257 3.55556 2.62071 4.37333 2.62071 5.33333C2.62071 6.31111 3.54066 6.66667 5.48672 7.13778C7.36201 7.60889 9.69728 8.19556 9.69728 10.6667C9.69728 12.2578 8.39696 13.6089 6.60128 14.0622V16H3.94756V14.0622C2.15188 13.6089 0.851562 12.2578 0.851562 10.6667Z"
      fill="currentColor"
    />
  </svg>
);

export const tick = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 184 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="91.9999" cy="91.9999" r="79.7333" fill="currentColor" />
    <circle cx="92" cy="92" r="91.5" stroke="currentColor" />
    <path
      d="M141.067 62.9389L73.7755 128.8L42.9336 98.6138L50.8403 90.8751L73.7755 113.268L133.16 55.2002L141.067 62.9389Z"
      fill="white"
    />
  </svg>
);

export const smallTick = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 184 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="91.9999" cy="91.9999" r="79.7333" fill="currentColor" />
    <circle cx="92" cy="92" r="91.5" stroke="currentColor" />
    <path
      d="M141.067 62.9389L73.7755 128.8L42.9336 98.6138L50.8403 90.8751L73.7755 113.268L133.16 55.2002L141.067 62.9389Z"
      fill="white"
    />
  </svg>
);

export const cross = (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28.5" cy="28.5001" r="24.4286" fill="currentColor" />
    <circle cx="28.5" cy="28.5" r="28" stroke="currentColor" />
    <path
      d="M42.75 17.1204L39.8796 14.25L28.5 25.6296L17.1204 14.25L14.25 17.1204L25.6296 28.5L14.25 39.8796L17.1204 42.75L28.5 31.3704L39.8796 42.75L42.75 39.8796L31.3704 28.5L42.75 17.1204Z"
      fill="white"
    />
  </svg>
);

export const locator = (
  <svg
    width="26"
    height="34"
    viewBox="0 0 26 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0.5C18.1087 0.5 22.25 4.58888 22.25 9.63333C22.25 11.3361 21.6723 13.2815 20.7712 15.2686C19.8732 17.2489 18.6713 19.2335 17.4621 21.0045C16.2538 22.7741 15.0446 24.3214 14.137 25.4266C13.6835 25.9789 13.3058 26.4201 13.042 26.7227C13.0277 26.7392 13.0137 26.7552 13 26.7709C12.9863 26.7552 12.9723 26.7392 12.958 26.7227C12.6942 26.4201 12.3165 25.9789 11.863 25.4266C10.9554 24.3214 9.74616 22.7741 8.53793 21.0045C7.32872 19.2335 6.12683 17.2489 5.22881 15.2686C4.32775 13.2815 3.75 11.3361 3.75 9.63333C3.75 4.58888 7.89128 0.5 13 0.5ZM13 5.97619C12.006 5.97619 11.0524 6.36958 10.349 7.07041C9.64554 7.77129 9.25 8.72229 9.25 9.71429C9.25 10.7063 9.64554 11.6573 10.349 12.3582C11.0524 13.059 12.006 13.4524 13 13.4524C13.994 13.4524 14.9476 13.059 15.651 12.3582C16.3545 11.6573 16.75 10.7063 16.75 9.71429C16.75 8.72229 16.3545 7.77129 15.651 7.07041C14.9476 6.36958 13.994 5.97619 13 5.97619ZM25.5 27.5238C25.5 29.0206 24.27 30.5106 21.9705 31.6562C19.7012 32.7866 16.5308 33.5 13 33.5C9.46922 33.5 6.29876 32.7866 4.02951 31.6562C1.72997 30.5106 0.5 29.0206 0.5 27.5238C0.5 26.6489 0.91328 25.7817 1.72765 24.9722C2.47716 24.2272 3.54771 23.5521 4.87283 23.0022L5.33574 23.6579C4.66813 23.984 4.09783 24.3614 3.66381 24.7867C3.10967 25.3297 2.75 25.9809 2.75 26.7143C2.75 27.448 3.11096 28.1012 3.667 28.6463C4.2214 29.1899 4.99773 29.6565 5.9163 30.0378C7.75569 30.8012 10.2612 31.2619 13 31.2619C15.7388 31.2619 18.2443 30.8012 20.0837 30.0378C21.0023 29.6565 21.7786 29.1899 22.333 28.6463C22.889 28.1012 23.25 27.448 23.25 26.7143C23.25 25.9809 22.8903 25.3297 22.3362 24.7867C21.9022 24.3614 21.3319 23.984 20.6643 23.6579L21.1272 23.0022C22.4523 23.5521 23.5228 24.2272 24.2723 24.9722C25.0867 25.7817 25.5 26.6489 25.5 27.5238Z"
      fill="currentColor"
      stroke="black"
    />
  </svg>
);

export const fromTo = (
  <svg
    width="30"
    height="8"
    viewBox="0 0 30 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="3" cy="4" r="2.5" stroke="#808080" />
    <path
      d="M26.6667 1.8C26.9193 1.8 27.1615 1.90536 27.3401 2.09289C27.5187 2.28043 27.619 2.53478 27.619 2.8C27.619 2.93132 27.5944 3.06136 27.5466 3.18268C27.4987 3.30401 27.4285 3.41425 27.3401 3.50711C27.2517 3.59997 27.1467 3.67362 27.0311 3.72388C26.9156 3.77413 26.7917 3.8 26.6667 3.8C26.4141 3.8 26.1718 3.69464 25.9932 3.50711C25.8146 3.31957 25.7143 3.06522 25.7143 2.8C25.7143 2.53478 25.8146 2.28043 25.9932 2.09289C26.1718 1.90536 26.4141 1.8 26.6667 1.8ZM26.6667 0C27.3739 0 28.0522 0.294999 28.5523 0.820101C29.0524 1.3452 29.3333 2.05739 29.3333 2.8C29.3333 4.9 26.6667 8 26.6667 8C26.6667 8 24 4.9 24 2.8C24 2.05739 24.281 1.3452 24.781 0.820101C25.2811 0.294999 25.9594 0 26.6667 0ZM26.6667 0.8C26.1615 0.8 25.677 1.01071 25.3198 1.38579C24.9626 1.76086 24.7619 2.26957 24.7619 2.8C24.7619 3.2 24.7619 4 26.6667 6.684C28.5714 4 28.5714 3.2 28.5714 2.8C28.5714 2.26957 28.3707 1.76086 28.0135 1.38579C27.6563 1.01071 27.1718 0.8 26.6667 0.8Z"
      fill="#228B22"
    />
    <circle cx="9" cy="4" r="1" fill="#808080" />
    <circle cx="13" cy="4" r="1" fill="#808080" />
    <circle cx="17" cy="4" r="1" fill="#808080" />
    <circle cx="21" cy="4" r="1" fill="#808080" />
  </svg>
);

export const previousTrips = [
  {
    id: 1,
    title:
      "Trip to Washington Square Park, Washington Square, New York, Ny, USA",
    date: "6 November 2022",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "3 days ago",
    completed: true,
  },
  {
    id: 2,
    title: "Trip to Florida, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    km: "245.5 mi",
    stop: "2 Stops",
    days: "5 days ago",
    completed: true,
  },
  {
    id: 3,
    title:
      "Trip to Washington Square Park, Washington Square, New York, Ny, USA",
    date: "6 November 2022",
    km: "245.5 mi",
    stop: "1 Stops",
    days: "1 days ago",
    completed: false,
  },
  {
    id: 4,
    title:
      "Trip to Washington Square Park, Washington Square, New York, Ny, USA",
    date: "6 November 2022",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "3 days ago",
    completed: true,
  },
  {
    id: 5,
    title: "Trip to Florida, Florida Square, New York, Ny, USA",
    date: "10 November 2022",
    km: "345.5 mi",
    stop: "5 Stops",
    days: "2 days ago",
    completed: false,
  },
  {
    id: 6,
    title:
      "Trip to Washington Square Park, Washington Square, New York, Ny, USA",
    date: "6 November 2022",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "3 days ago",
    completed: true,
  },
];

export const upComingTrips = [
  {
    id: 1,
    title:
      "Trip to Washington Square Park, Washington Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "7:31:9",
    completed: false,
  },
  {
    id: 2,
    title: "Florida Square Park, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "2 days",
    completed: true,
  },
  {
    id: 3,
    title: "Florida Square Park, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "2 days",
    completed: true,
  },
  {
    id: 4,
    title: "Florida Square Park, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "2 days",
    completed: true,
  },
  {
    id: 5,
    title: "Florida Square Park, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "2 days",
    completed: true,
  },
  {
    id: 6,
    title: "Florida Square Park, Florida Square, New York, Ny, USA",
    date: "6 November 2022",
    from: "California, CA",
    to: "Florida, FL",
    km: "245.5 mi",
    stop: "3 Stops",
    days: "2 days",
    completed: true,
  },
];

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    borderColor: "#1f1f1f",
    backgroundColor: "white",
    cursor: "pointer",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#228B22"
        : isFocused
        ? "#238b214a"
        : "white",
      color: isSelected ? "white" : isFocused ? "#228B22" : "#228B22",
      opacity: 1,
      cursor: "pointer",
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles, cursor: "pointer", color: "#228B22" }),
};

export const fuelOpt = [
  { id: 1, text: "Fuel Type 1", value: "fuel1" },
  { id: 2, text: "Fuel Type 2", value: "fuel2" },
  { id: 3, text: "Fuel Type 3", value: "fuel3" },
];

export const ownerOpt = [
  { id: 1, text: "Owner 1", value: "owner1" },
  { id: 2, text: "Owner 2", value: "owner2" },
  { id: 3, text: "Owner 3", value: "owner3" },
];

export const vehicleOpt = [
  { id: 1, text: "Vehicle 1", value: "vehicle1" },
  { id: 2, text: "Vehicle 2", value: "vehicle2" },
  { id: 3, text: "Vehicle 3", value: "vehicle3" },
];

export const radiusData = [
  { id: 1, text: "25 miles", value: 25 },
  { id: 2, text: "50 miles", value: 50 },
  { id: 3, text: "75 miles", value: 75 },
  { id: 4, text: "100 miles", value: 100 },
];
