import { clockSvg, locationOutlinedSvg, CalenderSvg } from "helper/helper";
import React from "react";

const TripDetail = () => {
  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10 flex flex-col gap-5'>
      <div className='flex flex-row justify-between items-center '>
        <h1 className='text-sm font-normal text-ezBlack '>Trip to Florida, USA</h1>
      </div>

      <div className='bg-white p-5 border border-ezGreen rounded grid grid-cols-2 md:grid-cols-4 gap-3'>
        <div>
          <p className='text-xs font-light mb-1'>Trip ID</p>
          <h5 className='text-sm font-medium'>Fleet Complete</h5>
        </div>
        <div>
          <p className='text-xs font-light mb-1'>User Email</p>
          <h5 className='text-sm font-medium'>Johnvixtor@gmail.com</h5>
        </div>
        <div>
          <p className='text-xs font-light mb-1'>User Name</p>
          <h5 className='text-sm font-medium'>Waqas</h5>
        </div>
        <div>
          <p className='text-xs font-light mb-1'>Org Name</p>
          <h5 className='text-sm font-medium'>Fleet Complete</h5>
        </div>
      </div>
      <div className='grid md:grid-cols-2 gap-4'>
        <div className='flex flex-col gap-3'>
          <div className='bg-white p-5 border border-ezRed rounded'>
            <p className='text-sm font-medium text-ezBlack'>Trip Details:</p>
            <div className='flex items-center justify-center mt-3 gap-2 text-ezBlack'>
              <span>{clockSvg}</span>
              <p>20:48:18</p>
            </div>
          </div>
          <div className='bg-white p-5 border border-ezGreen rounded flex flex-col gap-3'>
            <p className='text-sm font-medium text-ezBlack'>Trip Details:</p>
            <div className=' flex flex-col gap-3'>
              <div className='flex items-center gap-2 text-ezBlack'>
                <span>{locationOutlinedSvg}</span>
                <p className='text-xs font-medium text-ezLightGray'>Trip to Florida, USA</p>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex items-center gap-2 text-ezBlack'>
                  <span>{CalenderSvg}</span>
                  <p className='text-xs font-medium text-ezLightGray'>28 March 2023</p>
                </div>
                <div className='flex items-center gap-2 text-ezBlack mr-20'>
                  <span>{clockSvg}</span>
                  <p className='text-xs font-medium text-ezLightGray'>20 hours, 5 minutes</p>
                </div>
              </div>
            </div>
            <div className='border-y flex gap-3 items-center py-6 my-3'>
              <div className='border rounded border-ezGreen w-32 h-32'>
                <img src='/assests/images/carImg.jpg' alt='vehicle_img' />
              </div>
              <div className='text-ezBlack'>
                <p className='text-sm font-bold '>Rolls-Royce</p>
                <p className='text-xs font-medium '>Spectre</p>
              </div>
            </div>
            <div className='flex flex-col gap-2'>
              <p className='font-semibold'>
                Origin: <span className='font-normal'>USA</span>
              </p>
              <p className='font-semibold'>
                Destination: <span className='font-normal'>Florida, USA</span>
              </p>
              <p className='font-semibold'>
                Energy: <span className='font-normal'>84 kWh</span>
              </p>
              <p className='font-semibold'>
                Average Speed: <span className='font-normal'>65 mph</span>
              </p>
              <p className='font-semibold'>
                Total Miles: <span className='font-normal'>1303 miles</span>
              </p>
            </div>
          </div>
        </div>
        <div className='border border-ezGreen rounded'>map</div>
      </div>
    </div>
  );
};

export default TripDetail;
