import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdCancel } from "react-icons/md";
import PrimaryBtn from "components/btns/PrimaryBtn";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import useApiHook from 'hooks/useApiHook';
import { BsCameraFill, BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { countriesName, usaStates } from 'helper/countries';
import { colourStyles } from 'helper/helper';
import Avatar from 'react-avatar';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { BiLoaderCircle } from "react-icons/bi";
import { selectedUser as setAuthSelectedUser } from "redux/auth/authSlice";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name is too short.')
    .max(50, 'Name is too long.')
    .matches(/^[a-zA-Z\s]*$/, 'Name containt letters only')
    .required('Full Name is required')
    .trim(),
  email: Yup.string()
    .email('Please enter valid email')
    .trim(),
  phone: Yup.string().required('Phone number is requred').trim(),
  password: Yup.string()
    .min(8, 'Minimum 8 characters are required.')
    .max(16, 'Maximum 16 character are required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,16}$/,
      'Password must contain at least one number, one uppercase letter, one lowercase letter and one special character!'
    )
    .trim(),
    country: Yup.string().required('Country is required!').trim(),
    state: Yup.string()
        .min(2, 'State is too short!')
        .max(20, 'State is too long!')
        .trim(),
    city: Yup.string()
        .matches(
        /^[A-Za-z\s]*$/,
        'Enter the valid city and that contains letters only.'
        )
        .required('City is required!')
        .min(3, 'City is too short!')
        .max(20, 'City is too long!')
        .trim(),
    zipCode: Yup.string().min(3, 'Zip Code is too short!').trim(),
});

export default function UpdateUserModal({ selectedUser, isViewModal, setIsViewModal, ModalHeader }) {
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isTypePasssword, setIsTypePasssword] = useState(true);
  const [user, setUser] = useState(selectedUser);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  const updateProfile = async (values) => {
    const result = await handleApiCall({
      method: 'put',
      url: `admin/user/profile-update/${selectedUser?._id}`,
      data: values,
    });
    if (result?.status === 200) {
      toast.success('Profile updated successfully!');
      setIsViewModal(false);
      getUserInfo();
    }
  };

  const updateProfileImage = async (image) => {
    if (!image)
      return toast.error('Please select image to update the profile.');
    const formData = new FormData();
    formData.append('image', image);
    const result = await handleApiCall({
      method: 'put',
      url: `admin/user/profile-image/${selectedUser?._id}`,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (result?.status === 200) {
      toast.success('Profile image uploaded successfully!');
      getUserInfo();
    }
  };

  const getUserInfo = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `admin/user/profile/${selectedUser?._id}`,
    });
    if (result?.status === 200) {
        setUser(result.data?.user);
        dispatch(setAuthSelectedUser(result.data?.user));
    }
  };

  return (
    <Transition.Root show={isViewModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => setIsViewModal(true)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='w-full relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white p-0'>
                  <div className='ez__PlanTripHead w-full bg-ezGreen p-4 md:px-6 flex items-center justify-between'>
                    <p className='text-white'>{ModalHeader}</p>
                    <button
                      type='button'
                      onClick={() => {
                        setIsViewModal(false);
                      }}
                      className='bg-white text-ezGreen rounded-full block w-max'>
                      <MdCancel className='w-8 h-8' />
                    </button>
                  </div>
                  <div className='flex flex-col p-5'>
                    <div className="flex justify-center align-middle p-4">
                        <div className='block w-24 h-24 rounded-full overflow-hidden relative group'>
                        {isApiLoading ? (
                            <BiLoaderCircle className='w-24 h-24 text-ezGreen' />
                          ) : user?.profileImage ? (
                                <img
                                    src={user?.profileImage}
                                    alt='Profile Avatar'
                                    className='h-24 w-24 flex-none rounded-full object-cover'
                                />
                            ) : (
                                <div className='w-24 h-24 block'>
                                    <Avatar
                                        name={user?.name}
                                        round={true}
                                        size={100}
                                    />
                                </div>
                          )}
                          <label className='hidden group-hover:flex absolute top-0 left-0 w-full h-full items-center justify-center bg-black bg-opacity-40'>
                              <BsCameraFill className='w-10 h-10 text-white' />
                              <input
                                  type='file'
                                  onChange={(e) => updateProfileImage(e.target.files[0])}
                                  id='image'
                                  name='image'
                                  accept='image/*'
                                  className='absolute w-full h-full opacity-0 top-0 right-0 cursor-pointer'
                              />
                          </label>
                        </div>
                    </div>
                    <Formik
                        initialValues={selectedUser}
                        validationSchema={SignupSchema}
                        onSubmit={updateProfile}
                    >
                      {({ errors, values, setFieldValue, handleSubmit }) => (
                          <Form className='ez__Form w-full'>
                              <Field
                                  type='text'
                                  name='name'
                                  placeholder='Full Name'
                                  className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                              />
                              <ErrorMessage
                                  name='name'
                                  render={(msg) => (
                                    <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                        {msg}
                                    </p>
                                )}
                              />
                              <Field
                                readonly
                                disabled={true}
                                type='email'
                                name='email'
                                placeholder='Email'
                                className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                              />
                              <ErrorMessage
                                  name='email'
                                  render={(msg) => (
                                    <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                        {msg}
                                    </p>
                                )}
                              />
                              <div className='w-full block border border-ezBlack rounded-md text-sm text-ezBlack p-4 '>
                                  <PhoneInput
                                      placeholder='Enter phone number'
                                      value={values?.phone}
                                      onChange={(value) => setFieldValue('phone', value)}
                                      country='US'
                                      international={false}
                                      withCountryCallingCode
                                      defaultCountry='US'
                                      initialValueFormat='national'
                                      countries={['US']}
                                      rules={{
                                          required: true,
                                          validate: isPossiblePhoneNumber,
                                      }}
                                  />
                              </div>
                              <div className='mb-4'>
                                  {errors?.phone && (
                                      <p className='text-ezRed text-sm'>
                                          {errors?.phone}
                                      </p>
                                  )}
                              </div>
                              <Field
                                  type='text'
                                  name='city'
                                  placeholder='City'
                                  className='w-full block border border-ezBlack rounded-md text-sm text-ezBlack p-3.5 mb-4'
                              />
                              <ErrorMessage
                                  name='city'
                                  render={(msg) => (
                                      <p className='text-xs text-ezRed block mb-4 -mt-3'>{msg}</p>
                                  )}
                              />
                              <Select
                                  className='basic-single mb-4'
                                  classNamePrefix='select'
                                  placeholder='Select State'
                                  isClearable={true}
                                  isSearchable={true}
                                  styles={colourStyles}
                                  name='country'
                                  value={
                                      values?.state && {
                                          label: values?.state,
                                          value: values?.state,
                                      }
                                  }
                                  onChange={(state) => setFieldValue('state', state?.value)}
                                  options={usaStates?.map((state) => {
                                      return {
                                          label: state,
                                          value: state,
                                      };
                                  })}
                              />
                              <ErrorMessage
                                  name='state'
                                  render={(msg) => (
                                      <p className='text-sm text-ezRed block mb-4 -mt-3'>{msg}</p>
                                  )}
                              />
                              <Select
                                  className='basic-single mb-4'
                                  classNamePrefix='select'
                                  placeholder='Select Country'
                                  isSearchable={true}
                                  styles={colourStyles}
                                  name='country'
                                  value={
                                      values?.country && {
                                          label: values?.country,
                                          value: values?.country,
                                      }
                                  }
                                  onChange={(country) => setFieldValue('country', country?.value)}
                                  options={countriesName?.map((country) => {
                                      return {
                                          label: country,
                                          value: country,
                                      };
                                  })}
                              />
                              <ErrorMessage
                                  name='country'
                                  render={(msg) => (
                                      <p className='text-xs text-ezRed block mb-4 -mt-3'>{msg}</p>
                                  )}
                              />
                              <Field
                                  type='text'
                                  name='zipCode'
                                  placeholder='Zip Code'
                                  className='w-full block border border-ezBlack rounded-md text-sm text-ezBlack p-3.5 mb-4'
                              />
                              <div className='relative w-full'>
                                  <Field
                                      type={isTypePasssword ? 'password' : 'text'}
                                      name='password'
                                      placeholder='Password'
                                      className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                                  />
                                  <button
                                      type='button'
                                      onClick={() => setIsTypePasssword(!isTypePasssword)}
                                      className='absolute top-5 right-4 z-50 w-max text-ezBlack hover:text-ezGreen'
                                  >
                                      {isTypePasssword ? (
                                        <BsFillEyeSlashFill className='w-5 h-5' />
                                      ) : (
                                        <BsFillEyeFill className='w-5 h-5 ' />
                                      )}
                                  </button>
                              </div>
                              <ErrorMessage
                                  name='password'
                                  render={(msg) => (
                                    <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                        {msg}
                                    </p>
                                )}
                              />
                              <PrimaryBtn
                                  btnType='submit'
                                  onClick={handleSubmit}
                                  isApiLoading={isApiLoading}
                                  text='Submit'
                                  classNames={'mb-0'}
                              />
                          </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
