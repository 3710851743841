import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLogin, updateUser } from 'redux/auth/authSlice';
import { toast } from 'react-toastify';
import { AiOutlineLogout } from 'react-icons/ai';
import Spiner from 'helper/Spiner';
import useApiHook from 'hooks/useApiHook';

const UserDropDown = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleApiCall } = useApiHook();

  const handleLogout = () => {
    dispatch(toggleLogin({ isLogin: false, userInfo: null }));
    navigate('/login');
  };

  return (
    <Fragment>
      <Popover className='relative'>
        {() => (
          <>
            <Popover.Button className='flex items-center text-sm focus:outline-none'>
              {auth?.userInfo?.user?.profileImage ? (
                <img
                  className='h-6 w-6 sm:h-8 sm:w-8 rounded-full border border-ezGreen block'
                  src={auth?.userInfo?.user?.profileImage}
                  alt='User Profile'
                />
              ) : (
                <div className='w-8 h-8 rounded-full overflow-hidden'>
                  <Avatar
                    name={auth?.userInfo?.user?.name}
                    round={true}
                    size={32}
                  />
                </div>
              )}
              <span className='ml-3 text-ezBlack text-base hidden sm:block'>
                {auth?.userInfo?.user?.name?.slice(0, 9)}{' '}
                {auth?.userInfo?.user?.name?.length > 9 ? '...' : ''}
              </span>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute right-0 z-10 mt-1 w-screen max-w-md transform px-2 sm:px-0'>
                <div className='absolute right-0 z-10 mt-2 w-11/12 md:w-full max-w-md origin-top-right rounded-3xl bg-ezLightWhite shadow-lg focus:outline-none overflow-hidden'>
                  <div className='relative w-full block'>
                    <div className='bg-white p-5 rounded-3xl m-2'>
                      <div className='ez__ProfilePicDiv flex flex-col sm:flex-row items-center py-5 px-3'>
                        <div className=' block rounded-full w-16 h-16 relative z-1 group'>
                          {auth?.userInfo?.user?.profileImage ? (
                            <img
                              src={auth?.userInfo?.user?.profileImage}
                              alt='Profile Avatar'
                              className='w-16 h-16 object-cover rounded-full'
                            />
                          ) : (
                            <div className='w-16 h-16 block'>
                              <Avatar
                                name={auth?.userInfo?.user?.name}
                                round={true}
                                size={80}
                              />
                            </div>
                          )}
                        </div>
                        <div className='block w-max sm:ml-3 pl-4'>
                          <p className='text-ezGray flex text-sm font-bold items-center mb-1'>
                            {auth?.userInfo?.user?.name}
                          </p>
                          <p className='text-ezGray flex text-sm items-center'>
                            {auth?.userInfo?.user?.email}
                          </p>
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={handleLogout}
                      className='w-full flex items-center text-left  hover:bg-ezLightGreen px-8 py-3 border-b border-ezGray text-sm text-ezGreen hover:text-ezGreen'
                    >
                      <AiOutlineLogout className='w-6 h-6 mr-5' /> Sign out
                    </button>
                    <div className='flex items-start justify-center w-full py-3'>
                      <Link
                        to='/privacy-policy'
                        className='block p-2 text-ezGreen hover:text-ezGreen text-xs hover:bg-ezLightGreen rounded-md'
                      >
                        Privacy Policy
                      </Link>
                      <span className=' text-ezGreen mx-2'>.</span>
                      <Link
                        to='/terms-and-conditions'
                        className='block p-2 text-ezGreen hover:text-ezGreen text-xs hover:bg-ezLightGreen rounded-md'
                      >
                        Terms & Conditions
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Fragment>
  );
};

export default UserDropDown;
