import {
  homeSvg,
  tripCarSvg,
  searchVehicle,
  chargingSvg,
  userSvg,
} from 'helper/helper';

import { AiFillQuestionCircle, AiOutlineBell } from 'react-icons/ai';
import { BsBookmarkPlus, BsGlobe } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className='ez__SidebarDiv w-full h-full block '>
      <div className='block lg:hidden space-y-4 px-3 pt-10'>
        <div className='flex items-center justify-end'>
          <button
            type='button'
            className='p-2 text-ezGreen font-semibold focus:outline-none'
          >
            <BsGlobe className='h-5 w-5' aria-hidden='true' />
          </button>
          <span className=' bg-gray-200 h-7 w-px mx-2 block'></span>
          <button
            type='button'
            className='p-2 text-ezGreen font-semibold focus:outline-none block'
          >
            <BsBookmarkPlus className='h-5 w-5' aria-hidden='true' />
          </button>
          <span className=' bg-gray-200 h-7 w-px mx-2 block'></span>
          <button
            type='button'
            className='p-2 text-ezGreen  font-semibold focus:outline-none block'
          >
            <AiOutlineBell className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
      </div>
      <ul className='ez__NavItems w-full p-4 md:px-6 border-b border-gray-300 pt-10'>
        <li>
          <NavLink
            to='/dashboard/'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <span className='block mr-3'>{homeSvg}</span>
            Dashboard
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to='/organizations'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'>
            <span className='block mr-3'>{organizationSvg}</span>
            Organizations
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to='/users'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <span className='block mr-3'>{userSvg}</span>
            Users
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/trips'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <span className='block mr-3'>{tripCarSvg}</span>
            Trips
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/vehicles'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <span className='block mr-3'>{searchVehicle}</span>
            Vehicles
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to='/charging-activities'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <span className='block mr-3'>{chargingSvg}</span>
            Charging Activity
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to='/ads-management'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'>
            <span className='block mr-3'>{loudMicrophoneSvg}</span>
              Ads Management
          </NavLink>
        </li> */}
        <li>
          <NavLink
            to='/dashboard/feedback'
            className='text-sm flex items-center py-2 md:py-3 px-5 text-ezBlack hover:bg-ezLightGreen bg-transparent rounded-md'
          >
            <AiFillQuestionCircle className='w-5 h-5 mr-2' />
            Feedback
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
