import React, { useEffect, useState } from "react";
import PrimaryBtn from "components/btns/PrimaryBtn";
import {
  USER_DETAIL_TRIPS_COLUMNS,
  USER_DETAIL_VEHICLE_COLUMNS
} from "helper/data";
import { BasicTable } from "components/BasicTable/BasicTable";
import OutlineBtn from "components/btns/OutlineBtn";
import useApiHook from "hooks/useApiHook";
import Spiner from "helper/Spiner";
import DeleteModal from "modals/common/DeleteModal";
import UpdateUserModal from "modals/user/UpdateUserModal";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const UserDetail = () => {
  const { auth } = useSelector((state) => state);
  const [activeTab, setActiveTab] = useState("vehicles");
  const { handleApiCall, isApiLoading } = useApiHook();
  const [allTrips, setAllTrips] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();

  const getUserTrips = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `admin/trip/user/${auth?.selectedUser?._id}`,
    });
    if (result?.status === 200) {
      setAllTrips(result?.data?.trips);
    }
  };

  const getUserVehicles = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `admin/vehicle/${auth?.selectedUser?._id}`,
    });
    if (result.status === 200) {
      setAllVehicles(result?.data);
    }
  };

  const deleteUser = async () => {
    const result = await handleApiCall({
      method: 'delete',
      url: `admin/user/delete/${auth?.selectedUser?._id}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (result?.status === 200) {
      setDeleteModalOpen(false);
      navigate('/users');
      toast.success(result?.data?.message);
    }
  };

  useEffect(() => {
    if (auth?.selectedUser) {
      getUserTrips();
      getUserVehicles();
    };
  }, []);

  return (
    <>
      <div className='ez__Home w-full relative z-1 p-5 pb-10 flex flex-col gap-5'>
        {/* header title */}
        <div className='flex flex-row justify-between items-center '>
          <h1 className='text-2xl font-semibold text-ezBlack '>User Detail</h1>
          <div className='flex gap-2'>
            <OutlineBtn
              btnType='submit'
              text='Delete'
              deletebtn
              handleEvent={() => setDeleteModalOpen(true)}
            />
            <OutlineBtn
              btnType='submit'
              text='Edit'
              handleEvent={() => setUpdateModalOpen(true)}
            />
          </div>
        </div>
        <div className='bg-ezMidGray p-5 rounded grid grid-cols-2 md:grid-cols-3 gap-3'>
          <div>
            <p className='text-xs font-light'>User Name</p>
            <h5 className='text-sm font-medium'>{auth?.selectedUser?.name}</h5>
          </div>
          <div>
            <p className='text-xs font-light'>User Email</p>
            <h5 className='text-sm font-medium'>{auth?.selectedUser?.email}</h5>
          </div>
          <div>
            <p className='text-xs font-light'>Phone Number</p>
            {auth?.selectedUser?.phone ? <h5 className='text-sm font-medium'>{auth?.selectedUser?.phone}</h5> : <h5>-</h5>}
          </div>

          <div className='cursor-pointer'>
            <p className='text-xs font-light'>Total Vehicle</p>
            <h5 className='text-sm font-medium'>{allVehicles?.length}</h5>
          </div>
          <div className='cursor-pointer'>
            <p className='text-xs font-light'>Total Trips</p>
            <h5 className='text-sm font-medium'>{allTrips?.length}</h5>
          </div>
          <div>
            <p className='text-xs font-light'>Address</p>
            {auth?.selectedUser?.city ? 
              <h5 className='text-sm font-medium'>{auth?.selectedUser?.city}, {auth?.selectedUser?.country}, {auth?.selectedUser?.state}</h5> : 
              <h5>-</h5>
            }
          </div>
        </div>
        {/* tabs */}
        <div className='flex flex-col'>
          <div className='flex border-b items-center'>
            <div
              onClick={() => setActiveTab("vehicles")}
              className={`py-2 px-5 cursor-pointer ${
                activeTab === "vehicles" ? "text-white bg-ezGreen rounded-t-lg" : "text-ezLightBlue"
              }`}>
              Vehicles ({allVehicles.length})
            </div>
            <div
              onClick={() => setActiveTab("trips")}
              className={`py-2 px-5 cursor-pointer ${
                activeTab === "trips" ? "text-white bg-ezGreen rounded-t-lg" : "text-ezLightBlue"
              }`}>
              Trips
            </div>
          </div>
          <div className='py-3'>
            <div className='ez__Home w-full relative z-1 pb-10'>
              <div className='flex flex-row justify-between items-center'>
                <h1 className='text-2xl font-semibold text-ezBlack'>
                  {activeTab === "vehicles" ? "Vehicles" : "Trips"}
                </h1>
                <PrimaryBtn
                  btnType='submit'
                  text={activeTab === "vehicles" ? "Assign a Vehicle" : "Assign a Trip"}
                  width
                  //   handleEvent={() => setIsAddUserModal(true)}
                /> 
              </div>
              {isApiLoading ? (
                <div className='col-span-3 block w-full h-96'>
                  <Spiner color='ezGreen' />
                </div>
              ) : ( 
                <div className=' w-full block card bg-white rounded-md shadow-sm  border'>
                  <BasicTable
                    COLUMNS={
                      activeTab === "vehicles" ? USER_DETAIL_VEHICLE_COLUMNS : USER_DETAIL_TRIPS_COLUMNS
                    }
                    ROWS={activeTab === "vehicles" ? allVehicles : allTrips}
                    paginationText={activeTab === "vehicles" ? "Vehicle" : "Trip"}
                    view={activeTab === "vehicles" ? "vehicles" : "trips"}
                    searchText={activeTab === "vehicles" ? "Search vehicle" : "Search trip"}
                    actions={false}
                    pageNumber={1}
                    pageCount={10}
                    totalCount={activeTab === "vehicles" ? allVehicles?.length : allTrips?.length}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {deleteModalOpen && (
        <DeleteModal
          isModal={deleteModalOpen}
          isLoading={isApiLoading}
          handleCloseModal={() => setDeleteModalOpen(false)}
          title={`Delete ${auth?.selectedUser?.name?.split(' ')[0]}'s account?`}
          description='Are you sure you want to delete this user? 
            Data including trips, vehicles and charging activities
            against this user will be deleted.'
          handleDelete={deleteUser}
        />
      )}
      {updateModalOpen && (
        <UpdateUserModal
          selectedUser={auth?.selectedUser}
          ModalHeader={'Update User'}
          isViewModal={updateModalOpen}
          isLoading={isApiLoading}
          setIsViewModal={setUpdateModalOpen}
        />
      )}
    </>
  );
};

export default UserDetail;
