import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import SignIn from 'pages/auth/signIn/SignIn';
import Layout from 'pages/dashboard/Layout';

const useAuthRoutes = () => {
  const routes = useRoutes([
    { path: '/login', exact: true, element: <SignIn /> },
    {
      path: '*',
      element: <Layout />,
    },
  ]);
  return routes;
};

export default useAuthRoutes;
