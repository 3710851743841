import { BasicTable } from "components/BasicTable/BasicTable";
import PrimaryBtn from "components/btns/PrimaryBtn";
import { PAYMENT_COLUMNS, PAYMENT_DATA } from "helper/data";
import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const Payment = () => {
  const handleAddPayment = () => {};

  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10'>
      <div className='flex flex-row justify-between items-center'>
        <div>
          {" "}
          <NavLink
            to='/organizations/'
            className='text-ezGreen text-base font-medium hover:text-ezGreen flex items-center'>
            <BsArrowLeft className='inline w-6 h-6 mr-2' />
            Back
          </NavLink>
          <h1 className='text-2xl font-semibold text-ezBlack mb-2'>Organizations Payment Method</h1>
        </div>
        <PrimaryBtn
          btnType='submit'
          text='Add new payment method'
          width
          handleEvent={handleAddPayment}
        />
      </div>

      <div className=' w-full block card bg-white rounded-md shadow-sm  border'>
        <BasicTable
          COLUMNS={PAYMENT_COLUMNS}
          ROWS={PAYMENT_DATA}
          paginationText='Cards'
          view='payment'
          searchText='Search card number'
        />
      </div>
    </div>
  );
};

export default Payment;
