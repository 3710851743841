import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdCancel } from "react-icons/md";
import { BasicTable } from "components/BasicTable/BasicTable";

export default function CommonTableModal({
  isViewModal,
  setIsViewModal,
  ModalHeader,
  column,
  row,
}) {
  const cancelButtonRef = useRef(null);
  console.log(isViewModal);
  return (
    <Transition.Root show={isViewModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => setIsViewModal(true)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='w-full relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl'>
                <div className='bg-white p-0'>
                  <div className='ez__PlanTripHead w-full bg-ezGreen p-4 md:px-6 flex items-center justify-between'>
                    <p className='text-white'>{ModalHeader}</p>{" "}
                    <button
                      type='button'
                      onClick={() => {
                        setIsViewModal(false);
                      }}
                      className='bg-white text-ezGreen rounded-full block w-max'>
                      <MdCancel className='w-8 h-8' />
                    </button>
                  </div>
                  <div className='p-2'>
                    <BasicTable COLUMNS={column} ROWS={row} />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
