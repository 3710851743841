import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Loader from 'helper/Loader';
import useApiHook from 'hooks/useApiHook';
import { toast } from 'react-toastify';
import { toggleLogin } from 'redux/auth/authSlice';
import PrimaryBtn from 'components/btns/PrimaryBtn';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .required('Email is required')
    .trim(),
  password: Yup.string()
    .min(8, 'Minimum 8 character are required!')
    .max(20, 'Maximum 20 character are required!')
    .required('Password is required')
    .trim(),
});

const SignIn = () => {
  const { auth } = useSelector((state) => state);
  const { handleApiCall, isApiLoading } = useApiHook();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    const result = await handleApiCall({
      method: 'post',
      url: '/admin/login',
      data: {
        ...values,
        registerMethod: 'email',
      },
      headers: { Authorization: 'none' },
    });
    if (result.status === 200) {
      dispatch(
        toggleLogin({
          isLogin: true,
          userInfo: result?.data,
        })
      );
      toast.success('You have login successfully!');
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    if (auth?.isLogin) navigate('/dashboard');
  }, [auth?.isLogin]);

  return (
    <div className='ez__AuthPage w-full ez__Login relative'>
      {isApiLoading && <Loader background='transparency' />}
      <div className='max-w-7xl mx-auto px-8'>
        <div className='grid grid-cols1 lg:grid-cols-3 gap-10 items-center py-24 border-b-8 border-ezGreen w-full min-h-screen'>
          <div className='hidden lg:block lg:col-span-2 w-full'>
            <img
              src='/assets/images/login.png'
              alt='Login'
              className='w-full h-auto'
            />
          </div>
          <div className='ez__AuthForm'>
            <div className='title w-full flex items-center justify-center flex-col'>
              <img
                src='/assets/images/logo.png'
                alt='Logo'
                className='h-20 w-auto block mb-8'
              />
              <h3 className='text-2xl font-semibold mb-4 text-ezBlack'>
                Log into your account
              </h3>
              <p className='text-base mb-8 text-ezBlack'>
                Enter the fields below to get started
              </p>
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={handleLogin}
              >
                {() => (
                  <Form className='ez__Form w-full'>
                    <Field
                      type='email'
                      name='email'
                      placeholder='Email'
                      className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                    />
                    <ErrorMessage
                      name='email'
                      render={(msg) => (
                        <p className='text-sm text-ezRed block mb-4 -mt-3'>
                          {msg}
                        </p>
                      )}
                    />
                    <Field
                      type='password'
                      name='password'
                      placeholder='Password'
                      className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                    />
                    <ErrorMessage
                      name='password'
                      render={(msg) => (
                        <p className='text-sm text-ezRed block mb-4 -mt-3'>
                          {msg}
                        </p>
                      )}
                    />
                    <PrimaryBtn
                      btnType='submit'
                      isApiLoading={isApiLoading}
                      text='Login'
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
