import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div className='mx-8'>
      <h3 className="mb-3 text-ezBlack font-semibold ">Indicators:</h3>
      {payload.map((entry, index) => (
        <p key={`item-${index}`} className='capitalize flex items-center my-1'>
          <span
            className='inline-block mr-2 w-4 h-4 rounded bg-gray-500'
            style={{ backgroundColor: entry.color }}></span>
          {entry.value}
        </p>
      ))}
    </div>
  );
};

const DashboardBarChart = ({ data, colors }) => {
  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart data={data} barGap={0}>
        <XAxis
          dataKey='name'
          tickLine={false}
          stroke='#AEAEAE'
          axisLine={{ stroke: "#ECECEC" }}
          fontSize={10}
          fontWeight={400}
          fontFamily='Poppins'
        />
        <YAxis
          stroke='#AEAEAE'
          tickLine={false}
          axisLine={{ stroke: "#ECECEC" }}
          fontSize={10}
          fontWeight={400}
          fontFamily='Poppins'
        />

        <Tooltip />
        <Legend align='right' verticalAlign='top' layout='vertical' content={<CustomLegend />} />
        <Bar dataKey='users' fill={colors[0].Users} />
        <Bar dataKey='trips' fill={colors[1].Trips} />
        <Bar dataKey='vehicles' fill={colors[2].Vehicle} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DashboardBarChart;
