import * as React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from 'pages/dashboard/Dashboard';
import Feedback from 'pages/dashboard/feedback/Feedback';
import FeedbackDetail from 'pages/dashboard/feedback/detail/FeedbackDetail';
import Vehicles from 'pages/dashboard/Vehicle/Vehicles';
import Trips from 'pages/dashboard/Trips/Trips';
import TripDetail from 'pages/dashboard/Trips/TripDetail';
import UserDetail from 'pages/dashboard/users/UserDetail';
import VehicleDetail from 'pages/dashboard/Vehicle/VehicleDetail';
import Payment from 'pages/dashboard/payment';
import Users from 'pages/dashboard/users/Users';

const useProtectedRoutes = () => {
  const routes = useRoutes([
    { path: '/dashboard', exact: true, element: <Dashboard /> },
    { path: '/dashboard/feedback', exact: true, element: <Feedback /> },
    {
      path: '/dashboard/feedback/:feedbackId',
      exact: true,
      element: <FeedbackDetail />,
    },

    {
      path: '/users',
      exact: true,
      element: <Users />,
    },
    {
      path: '/users/:userId',
      exact: true,
      element: <UserDetail />,
    },
    {
      path: '/vehicles',
      exact: true,
      element: <Vehicles />,
    },
    {
      path: '/vehicles/:orgId',
      exact: true,
      element: <VehicleDetail />,
    },
    {
      path: '/trips',
      exact: true,
      element: <Trips />,
    },
    {
      path: '/trips/:tripId',
      exact: true,
      element: <TripDetail />,
    },
    // {
    //   path: '/organizations',
    //   exact: true,
    //   element: <Organizations />,
    // },
    // {
    //   path: '/organizations/:orgId',
    //   exact: true,
    //   element: <OrganizationTrips />,
    // },
    // {
    //   path: '/organizations/organization-vehicle/:orgId',
    //   exact: true,
    //   element: <OrganizationVehicles />,
    // },
    // {
    //   path: '/organizations/organization-trips/:orgId',
    //   exact: true,
    //   element: <OrganizationAllTrips />,
    // },
    // {
    //   path: '/organizations/organization-all-users/:orgId',
    //   exact: true,
    //   element: <OrganizationAllUsers />,
    // },
    // {
    //   path: '/charging-activities',
    //   exact: true,
    //   element: <ChargingActivity />,
    // },
    // {
    //   path: '/charging-activities/:activityId',
    //   exact: true,
    //   element: <ChargingActivityDetail />,
    // },
    {
      path: '/payment',
      exact: true,
      element: <Payment />,
    },
    { path: '*', element: <Navigate to='/dashboard' /> },
  ]);
  return routes;
};

export default useProtectedRoutes;
