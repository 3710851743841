import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebar: false,
  isPlanModal: false,
  isPlanApiCall: false,
  isVehicleApiCall: false,
  isDashboardDataApiCall: false,
  isEmptyFilters: false,
  isDeleteModalOpen: false,
  vehicleManufactures: [],
  reviews: [],
  allUsers: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    handlPlanTripModal: (state, { payload }) => {
      state.isPlanModal = payload;
    },
    handlSidebar: (state, { payload }) => {
      state.isSidebar = payload;
    },
    toogleVehicleManufactures: (state, { payload }) => {
      state.vehicleManufactures = payload;
    },
    toggleIsPlanApiCall: (state, { payload }) => {
      state.isPlanApiCall = payload;
    },
    toggleIsVehicleApiCall: (state, { payload }) => {
      state.isVehicleApiCall = payload;
    },
    toggleIsDashboardApiCall: (state, { payload }) => {
      state.isDashboardDataApiCall = payload;
    },
    toggleEmptyFilters: (state, { payload }) => {
      state.isEmptyFilters = payload;
    },
    toggleIsDeleteModalOpen: (state, { payload }) => {
      state.isDeleteModalOpen = payload;
    },
    handleReviews: (state, { payload }) => {
      state.reviews = payload;
    },
    handleUsersData: (state, { payload }) => {
      state.allUsers = payload;
    }
  },
});

export const {
  handlPlanTripModal,
  handlSidebar,
  toogleVehicleManufactures,
  toggleIsPlanApiCall,
  toggleIsVehicleApiCall,
  toggleIsDashboardApiCall,
  toggleEmptyFilters,
  handleReviews,
  handleUsersData,
  toggleIsDeleteModalOpen,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
