import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdCancel } from "react-icons/md";
import PrimaryBtn from "components/btns/PrimaryBtn";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import useApiHook from 'hooks/useApiHook';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name is too short.')
    .max(50, 'Name is too long.')
    .matches(/^[a-zA-Z\s]*$/, 'Name containt letters only')
    .required('Full Name is required')
    .trim(),
  email: Yup.string()
    .email('Please enter valid email')
    .required('Email is required')
    .trim(),
  phone: Yup.string().required('Phone number is requred').trim(),
  password: Yup.string()
    .min(8, 'Minimum 8 characters are required.')
    .max(16, 'Maximum 16 character are required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,16}$/,
      'Password must contain at least one number, one uppercase letter, one lowercase letter and one special character!'
    )
    .required('Password is required')
    .trim(),
});

const initialState = {
  name: '',
  email: '',
  password: '',
  phone: ''
};

export default function AddUserModal({ isViewModal, setIsViewModal, ModalHeader }) {
  const { handleApiCall, isApiLoading } = useApiHook();
  const [userEmail, setUserEmail] = useState('');
  const [isTypePasssword, setIsTypePasssword] = useState(true);
  const [isVerification, setIsVerification] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleSignUp = async (values) => {
    if (!isPossiblePhoneNumber(values?.phone))
      return toast.error('Invalid phone number');
    setUserEmail(values?.email);
    const result = await handleApiCall({
      method: 'post',
      url: '/admin/user/add',
      data: {
        ...values,
        registerMethod: 'email',
        platform: 'web',
      }
    });
    if (result.status === 200) {
      toast.success(result?.data?.message);
      setIsVerification(true);
    }
  };

  return (
    <Transition.Root show={isViewModal} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => setIsViewModal(true)}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
              <Dialog.Panel className='w-full relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
                <div className='bg-white p-0'>
                  <div className='ez__PlanTripHead w-full bg-ezGreen p-4 md:px-6 flex items-center justify-between'>
                    <p className='text-white'>{ModalHeader}</p>
                    <button
                      type='button'
                      onClick={() => {
                        setIsViewModal(false);
                      }}
                      className='bg-white text-ezGreen rounded-full block w-max'>
                      <MdCancel className='w-8 h-8' />
                    </button>
                  </div>
                  <div className='flex flex-col p-5'>
                    {isVerification ? (
                      <div className='py-10 rounded-md flex flex-col items-center justify-center w-full h-full relative'>
                        <p className='text-ezBlack text-base text-center mb-3'>
                          A verification email has been sent to{' '}
                          <b className='text-ezGreen font-semibold'>{userEmail}</b>.
                        </p>
                      </div>
                    ) : (
                      <>
                        <Formik
                          initialValues={initialState}
                          validationSchema={SignupSchema}
                          onSubmit={handleSignUp}
                        >
                          {({ errors, values, setFieldValue }) => (
                            <Form className='ez__Form w-full'>
                              <Field
                                type='text'
                                name='name'
                                placeholder='Full Name'
                                className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                              />
                              <ErrorMessage
                                name='name'
                                render={(msg) => (
                                  <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                    {msg}
                                  </p>
                                )}
                              />
                              <Field
                                type='email'
                                name='email'
                                placeholder='Email'
                                className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                              />
                              <ErrorMessage
                                name='email'
                                render={(msg) => (
                                  <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                    {msg}
                                  </p>
                                )}
                              />
                              <div className='w-full block border border-ezBlack rounded-md text-sm text-ezBlack p-4 '>
                                <PhoneInput
                                  placeholder='Enter phone number'
                                  value={values?.phone}
                                  onChange={(value) => setFieldValue('phone', value)}
                                  country='US'
                                  international={false}
                                  withCountryCallingCode
                                  defaultCountry='US'
                                  initialValueFormat='national'
                                  countries={['US']}
                                  rules={{
                                    required: true,
                                    validate: isPossiblePhoneNumber,
                                  }}
                                />
                              </div>
                              <div className='mb-4'>
                                {errors?.phone && (
                                  <p className='text-ezRed text-sm'>
                                    {errors?.phone}
                                  </p>
                                )}
                              </div>
                              <div className='relative w-full'>
                                <Field
                                  type={isTypePasssword ? 'password' : 'text'}
                                  name='password'
                                  placeholder='Password'
                                  className='w-full block border border-ezBlack rounded-md text-base text-ezBlack p-4 mb-4'
                                />
                                <button
                                  type='button'
                                  onClick={() => setIsTypePasssword(!isTypePasssword)}
                                  className='absolute top-5 right-4 z-50 w-max text-ezBlack hover:text-ezGreen'
                                >
                                  {isTypePasssword ? (
                                    <BsFillEyeSlashFill className='w-5 h-5' />
                                  ) : (
                                    <BsFillEyeFill className='w-5 h-5 ' />
                                  )}
                                </button>
                              </div>
                              <ErrorMessage
                                name='password'
                                render={(msg) => (
                                  <p className='text-sm text-ezRed block mb-4 -mt-3'>
                                    {msg}
                                  </p>
                                )}
                              />
                              <PrimaryBtn
                                btnType='submit'
                                isApiLoading={isApiLoading}
                                text='Submit'
                                classNames={'mb-0'}
                              />
                            </Form>
                          )}
                        </Formik>
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
