import { useEffect, useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import useApiHook from 'hooks/useApiHook';
import { Link } from 'react-router-dom';
import Loader from 'helper/Loader';

const Feedback = () => {
  const { handleApiCall, isApiLoading } = useApiHook();
  const [feedbacks, setFeedbacks] = useState([]);

  const getFeedbacks = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: `/feedback`,
    });
    if (result.status === 200) {
      setFeedbacks(result?.data?.feedbacks);
    }
  };

  useEffect(() => {
    getFeedbacks();
  }, []);

  return (
    <div className='ez__MyVehicle w-full bg-ezMidWhite px-4 py-10 md:p-10'>
      {isApiLoading && <Loader background='transparency' />}
      <div className='ez__Title w-full flex md:items-center justify-between mb-8 flex-col md:flex-row'>
        <h3 className='text-ezBlack text-xl mb-3 md:mb-0'>All Feedbacks</h3>
      </div>

      <div className='ez__VehicleList block w-full'>
        <div className='px-4 sm:px-6 lg:px-8'>
          <div className='mt-8 flow-root'>
            <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
              <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                <table className='min-w-full divide-y divide-gray-300'>
                  <thead>
                    <tr>
                      <th
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        Name
                      </th>
                      <th
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        Title
                      </th>
                      <th
                        scope='col'
                        className='p-3 text-left text-sm font-semibold text-gray-900'
                      >
                        Description
                      </th>
                      <th
                        scope='col'
                        className='p-3 text-sm font-semibold text-gray-900'
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200 bg-white'>
                    {feedbacks?.length > 0 &&
                      feedbacks.map((feedback) => (
                        <tr key={feedback?._id}>
                          <td className='whitespace-nowrap p-3 text-sm'>
                            <div className='flex items-center'>
                              <div className='ml-1'>
                                <div className='font-medium text-sm text-ezBlack'>
                                  {feedback?.user?.name}
                                </div>
                                <div className='mt-1 text-ezGray text-xs'>
                                  {feedback?.user?.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap p-3 text-sm text-ezGray'>
                            {feedback?.title}
                          </td>
                          <td className='whitespace-nowrap p-3 text-sm text-ezGray'>
                            {feedback?.description?.length > 50
                              ? `${feedback.description?.slice(0, 50)}...`
                              : feedback?.description}
                          </td>
                          <td className='whitespace-nowrap p-3 text-sm text-ezGray'>
                            <Link
                              className='flex justify-center'
                              to={`/dashboard/feedback/${feedback?._id}`}
                            >
                              <AiFillEye className='w-5 md:w-6 h-5 md:h-6 text-ezGreen' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
