import Dropdown from 'components/Dropdown';
import CommonTableModal from 'modals/common/CommonTableModal';
import { useState } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { selectedUser } from 'redux/auth/authSlice';
import { useDispatch } from 'react-redux';

export const totalUserCard = [
  {
    label: 'Total users',
    value: '635',
    desc: '2% increase from last month',
  },
  {
    label: 'Total users',
    value: '888',
    desc: '2% increase from last month',
  },
  {
    label: 'Total users',
    value: '635',
    desc: '2% increase from last month',
  },
  {
    label: 'Total users',
    value: '635',
    desc: '2% increase from last month',
  },
];
export const dashboardTableHeader = [
  { id: 'tripId', label: 'Trip ID' },
  { id: 'userName', label: 'User Name' },
  { id: 'orgName', label: 'Org Name' },
  { id: 'route', label: 'Route' },
  { id: 'vehicle', label: 'Vehicle' },
  { id: 'status', label: 'Status' },
];
export const tripsTableHeader = [
  { id: 'tripId', label: 'Trip ID', isLink: true },
  { id: 'userName', label: 'User Name' },
  { id: 'orgName', label: 'Org Name' },
  { id: 'route', label: 'Route' },
  { id: 'vehicle', label: 'Vehicle' },
  { id: 'status', label: 'Status' },
];
export const UsertripsTableHeader = [
  { id: 'tripId', label: 'Trip ID', isLink: true },
  { id: 'userName', label: 'User Name' },
  { id: 'route', label: 'Route' },
  { id: 'vehicle', label: 'Vehicle' },
  { id: 'status', label: 'Status' },
];

export const dashboardTableData = [
  {
    tripId: '1',
    userName: 'John Victor',
    orgName: 'Geotab',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: '2',
    userName: 'John Victor',
    orgName: 'Geotab',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
  {
    tripId: '3',
    userName: 'John Victor',
    orgName: 'Geotab',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Upcoming',
  },
  {
    tripId: '56468',
    userName: 'John Victor',
    orgName: 'Geotab',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Not Schedule',
  },
];
// users Table

export const userTableData = [
  {
    fName: '56468',
    userEmail: 'John Victor',
    orgName: 'Geotab',
    vehicle: '5',
    trips: '2',
    city: 'In Progress',
    state: 'In Progress',
    country: 'In Progress',
    zip: 'In Progress',
  },
];

// organizations recent users table

export const orgRecentuserTableData = [
  {
    fName: 'John Victor',
    userEmail: 'John@gmail.com',
    vehicleMake: 'Rolls-Royce Spectre',
    totalTrips: '2',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '123213',
  },
  {
    fName: 'John Victor',
    userEmail: 'John@gmail.com',
    vehicleMake: 'Rolls-Royce Spectre',
    totalTrips: '2',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '123213',
  },
  {
    fName: 'John Victor',
    userEmail: 'John@gmail.com',
    vehicleMake: 'Rolls-Royce Spectre',
    totalTrips: '2',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '123213',
  },
];

// charging activity data

export const chargingActivityTableData = [
  {
    trackingId: '56468',
    userName: 'John Victor',
    orgName: 'Geotab',
    connectorName: 'EZVOLTZON001',
    stationAddress: 'Trip to Florida, USA',
    vehicle_Make_Model: 'Rolls-Royce, Spectre',
    status: 'Charging',
  },
  {
    trackingId: '56468',
    userName: 'John Victor',
    orgName: 'Geotab',
    connectorName: 'EZVOLTZON001',
    stationAddress: 'Trip to Florida, USA',
    vehicle_Make_Model: 'Rolls-Royce, Spectre',
    status: 'Complete',
  },
];
export const orgTableHeader = [
  { id: 'orgName', label: 'Org Name', isLink: true },
  { id: 'adminName', label: 'Admin Name' },
  { id: 'vehicle', label: 'Vehicle', isView: true },
  { id: 'employees', label: 'Employees', isView: true },
  { id: 'trips', label: 'Trips', isView: true },
  { id: 'status', label: 'Status' },
];
export const orgTableData = [
  {
    orgName: 'Geotab',
    adminName: 'John Victor',
    vehicle: '5',
    employees: '2',
    trips: '5',
    status: 'Pending',
  },
];

export const UserModalHeader = [
  { id: 'fName', label: 'Full Name' },
  { id: 'userEmail', label: 'User Email' },
  { id: 'orgName', label: 'Org Name' },
];
export const orgDriverModalHeader = [
  { id: 'fName', label: 'Full Name' },
  { id: 'userEmail', label: 'User Email' },
];

export const vehicleTableData = [
  {
    vehicleMake: 'Rolls-Royce',
    vehicleModel: 'Spectre',
    users: '10',
  },
  {
    vehicleMake: 'Aiways',
    vehicleModel: 'U6',
    users: '0',
  },
  {
    vehicleMake: 'Rolls-Royce',
    vehicleModel: 'Spectre',
    users: '10',
  },
];

export const orgVehicleTableData = [
  {
    vehicleMake: 'Rolls-Royce',
    vehicleModel: 'Spectre',
    drivers: '5',
    totalTrips: '2',
  },
  {
    vehicleMake: 'Rolls-Royce',
    vehicleModel: 'Spectre',
    drivers: '5',
    totalTrips: '2',
  },
  {
    vehicleMake: 'Rolls-Royce',
    vehicleModel: 'Spectre',
    drivers: '5',
    totalTrips: '2',
  },
];
// organizations Recent trips All
export const RecentTripTableHeader = [
  { id: 'tripId', label: 'Trip ID' },
  { id: 'userName', label: 'User Name' },
  { id: 'userEmail', label: 'User Email' },
  { id: 'route', label: 'Route' },
  { id: 'status', label: 'Status' },
];
export const RecentTripTableData = [
  {
    tripId: '56468',
    userName: 'John Victor',
    userEmail: 'John@gmail.com',
    route: 'Trip to Florida, USA',
    status: 'In Progress',
  },
  {
    tripId: '56468',
    userName: 'John Victor',
    userEmail: 'John@gmail.com',
    route: 'Trip to Florida, USA',
    status: 'Completed',
  },
  {
    tripId: '56468',
    userName: 'John Victor',
    userEmail: 'John@gmail.com',
    route: 'Trip to Florida, USA',
    status: 'In Progress',
  },
];

export const chartColors = [
  { Users: '#228B22' },
  { Trips: '#2E49B8' },
  { Vehicle: '#FFB800' },
];

export const tabData = {
  Daily: [
    {
      name: 'Jan',
      Users: 200,
      Trips: 100,
      Vehicle: 140,
    },
    {
      name: 'Feb',
      Users: 250,
      Trips: 120,
      Vehicle: 100,
    },
    {
      name: 'March',
      Users: 150,
      Trips: 10,
      Vehicle: 130,
    },
    {
      name: 'April',
      Users: 204,
      Trips: 130,
      Vehicle: 110,
    },
    {
      name: 'May',
      Users: 223,
      Trips: 123,
      Vehicle: 43,
    },
    {
      name: 'June',
      Users: 300,
      Trips: 120,
      Vehicle: 124,
    },
    {
      name: 'July',
      Users: 234,
      Trips: 244,
      Vehicle: 123,
    },
    {
      name: 'Aug',
      Users: 243,
      Trips: 104,
      Vehicle: 140,
    },
    {
      name: 'Sept',
      Users: 250,
      Trips: 123,
      Vehicle: 190,
    },
    {
      name: 'Oct',
      Users: 245,
      Trips: 103,
      Vehicle: 130,
    },
    {
      name: 'Nov',
      Users: 300,
      Trips: 105,
      Vehicle: 150,
    },
    {
      name: 'Dec',
      Users: 200,
      Trips: 22,
      Vehicle: 140,
    },
  ],
  Weekly: [
    {
      name: 'Jan',
      Users: 200,
      Trips: 100,
      Vehicle: 140,
    },
    {
      name: 'Feb',
      Users: 150,
      Trips: 120,
      Vehicle: 100,
    },
    {
      name: 'March',
      Users: 150,
      Trips: 102,
      Vehicle: 130,
    },
    {
      name: 'April',
      Users: 204,
      Trips: 20,
      Vehicle: 110,
    },
    {
      name: 'May',
      Users: 223,
      Trips: 12,
      Vehicle: 43,
    },
    {
      name: 'June',
      Users: 130,
      Trips: 120,
      Vehicle: 124,
    },
    {
      name: 'July',
      Users: 304,
      Trips: 234,
      Vehicle: 123,
    },
    {
      name: 'Aug',
      Users: 243,
      Trips: 104,
      Vehicle: 140,
    },
    {
      name: 'Sept',
      Users: 250,
      Trips: 123,
      Vehicle: 190,
    },
    {
      name: 'Oct',
      Users: 245,
      Trips: 103,
      Vehicle: 130,
    },
    {
      name: 'Nov',
      Users: 300,
      Trips: 105,
      Vehicle: 150,
    },
    {
      name: 'Dec',
      Users: 200,
      Trips: 22,
      Vehicle: 140,
    },
  ],
  Monthly: [
    {
      name: 'Jan',
      Users: 200,
      Trips: 100,
      Vehicle: 140,
    },
    {
      name: 'Feb',
      Users: 250,
      Trips: 120,
      Vehicle: 100,
    },
    {
      name: 'March',
      Users: 150,
      Trips: 10,
      Vehicle: 130,
    },
    {
      name: 'April',
      Users: 204,
      Trips: 130,
      Vehicle: 110,
    },
    {
      name: 'May',
      Users: 223,
      Trips: 123,
      Vehicle: 43,
    },
    {
      name: 'June',
      Users: 200,
      Trips: 120,
      Vehicle: 124,
    },
    {
      name: 'July',
      Users: 234,
      Trips: 104,
      Vehicle: 123,
    },
    {
      name: 'Aug',
      Users: 243,
      Trips: 104,
      Vehicle: 140,
    },
    {
      name: 'Sept',
      Users: 250,
      Trips: 143,
      Vehicle: 122,
    },
    {
      name: 'Oct',
      Users: 145,
      Trips: 103,
      Vehicle: 130,
    },
    {
      name: 'Nov',
      Users: 200,
      Trips: 105,
      Vehicle: 150,
    },
    {
      name: 'Dec',
      Users: 200,
      Trips: 22,
      Vehicle: 140,
    },
  ],
};

export const orgRecentuserTableHeader = [
  { id: 'fName', label: 'Full Name' },
  { id: 'userEmail', label: 'User Email' },
  { id: 'vehicleMake', label: 'Vehicle Make' },
  { id: 'totalTrips', label: 'Total Trips' },
  { id: 'city', label: 'City' },
  { id: 'state', label: 'State' },
  { id: 'country', label: 'Country' },
  { id: 'zip', label: 'Zip' },
];

// **************Organizations  Data ***************//

export const ORGANIZATIONSCOLUMNS = [
  {
    Header: 'Org Name',
    accessor: 'org_name',
    Cell: ({ value }) => (
      <NavLink to={`/organizations/${value}`}>{value}</NavLink>
    ),
  },
  {
    Header: 'Admin Name',
    accessor: 'admin_Name',
  },
  {
    Header: 'Vehicle',
    accessor: 'vehicle',
    Cell: ({ value }) => {
      return (
        <div className='flex items-center gap-1'>
          {value}
          {value > 0 && (
            <>
              <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
              <NavLink
                className='text-ezGreen cursor-pointer'
                to={`/organizations/organization-vehicle/${value.id}`}
              >
                view
              </NavLink>
            </>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Employees',
    accessor: 'employees',
    Cell: ({ value }) => {
      return (
        <div className='flex items-center gap-1'>
          {value}
          {value > 0 && (
            <>
              <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
              <NavLink
                className='text-ezGreen cursor-pointer'
                to={`/organizations/organization-all-users/${value.id}`}
              >
                view
              </NavLink>
            </>
          )}
        </div>
      );
    },
  },
  {
    Header: 'Trips',
    accessor: 'trips',
    Cell: ({ value }) => {
      return (
        <div className='flex items-center gap-1'>
          {value}
          {value > 0 && (
            <>
              <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
              <NavLink
                className='text-ezGreen cursor-pointer'
                to={`/organizations/organization-trips/${value.id}`}
              >
                view
              </NavLink>
            </>
          )}
        </div>
      );
    },
  },

  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <Dropdown icon />,
  },
];

export const ORGANIZATIONSDATA = [
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '3',
    status: 'Pending',
  },
  {
    org_name: 'Fleet Complete',
    admin_Name: 'Candice Wu',
    trips: '10',
    employees: '0',
    vehicle: '9',
    status: 'Accept',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '0',
    status: 'Pending',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '3',
    status: 'Pending',
  },
  {
    org_name: 'Fleet Complete',
    admin_Name: 'Candice Wu',
    trips: '10',
    employees: '0',
    vehicle: '9',
    status: 'Accept',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '0',
    status: 'Pending',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '3',
    status: 'Pending',
  },
  {
    org_name: 'Fleet Complete',
    admin_Name: 'Candice Wu',
    trips: '10',
    employees: '0',
    vehicle: '9',
    status: 'Accept',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '0',
    status: 'Pending',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '3',
    status: 'Pending',
  },
  {
    org_name: 'Fleet Complete',
    admin_Name: 'Candice Wu',
    trips: '10',
    employees: '0',
    vehicle: '9',
    status: 'Accept',
  },
  {
    org_name: 'Geotab',
    admin_Name: 'John Victor',
    trips: '0',
    employees: '5',
    vehicle: '0',
    status: 'Pending',
  },
];

export const ORGTRIPSCOLUMNS = [
  {
    Header: 'Trip ID',
    accessor: 'tripId',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'User Name',
    accessor: 'user_Name',
  },
  {
    Header: 'User Email',
    accessor: 'user_Email',
  },

  {
    Header: 'Route',
    accessor: 'route',
  },
  {
    Header: 'Vehicle',
    accessor: 'vehicle',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

// **************Organizations Trips Data ***************//

export const ORGTRIPSDATA = [
  {
    tripId: 56468,
    user_Name: 'Torie',
    user_Email: 'Rustman@gmail.com',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    user_Email: 'Rustman@gmail.com',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
];

// **************Organizations Trips Data ***************//

export const ORG_USERS_COLUMNS = [
  {
    Header: 'Full Name',
    accessor: 'full_Name',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'User Email',
    accessor: 'user_Email',
  },
  {
    Header: 'Vehicle Make',
    accessor: 'vehicle_Make',
  },
  {
    Header: 'Total Trips',
    accessor: 'total_Trips',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Trips'
              column={TRIPSCOLUMNS}
              row={TRIPSDATA}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: 'City',
    accessor: 'city',
  },

  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Zip',
    accessor: 'zip',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <Dropdown icon />,
  },
];

export const ORG_USERS_DATA = [
  {
    full_Name: 'Torie',
    vehicle_Make: 'Rolls-Royce. spectre',
    user_Email: 'Rustman@gmail.com',
    total_Trips: '5',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '33901',
  },
  {
    full_Name: 'CandiceWu',
    vehicle_Make: 'Rolls-Royce. spectre',
    user_Email: 'CandiceWu@gmail.com',
    total_Trips: '5',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '33901',
  },
];
// **************Trips Data ***************//

export const TRIPSCOLUMNS = [
  {
    Header: 'ID',
    accessor: '_id',
  },
  {
    Header: 'From',
    accessor: 'from',
  },
  {
    Header: 'To',
    accessor: 'to',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return (
        <span
          className={`inline-flex capitalize items-center rounded-full ${
            value === 'completed'
              ? 'bg-completed text-completed'
              : value === 'inprogress'
              ? 'bg-inprogress text-inprogress'
              : value === 'cancelled'
              ? 'bg-cancelled text-cancelled'
              : value === 'upcoming'
              ? 'bg-upcoming text-upcoming'
              : 'bg-ezGray text-ezGray'
          } bg-opacity-20 px-2 py-1 text-xs font-medium `}
        >
          {value === 'inprogress'
            ? 'In Progress'
            : value === 'upcoming'
            ? 'Upcoming'
            : value === 'notStarted'
            ? 'Not Started'
            : value === 'cancelled'
            ? 'Cancelled'
            : value}
        </span>
      );
    },
  },
  // {
  //   Header: 'Actions',
  //   accessor: 'actions',
  //   Cell: () =>
  //   (
  //     <Dropdown icon />
  //   )

  // },
];

export const TRIPSDATA = [
  {
    tripId: 56468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
  {
    tripId: 56468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
  {
    tripId: 56468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
  {
    tripId: 56468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
  {
    tripId: 56468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'In Progress',
  },
  {
    tripId: 256468,
    user_Name: 'Torie',
    org_name: 'Rustman',
    route: 'Trip to Florida, USA',
    vehicle: 'Rolls-Royce. spectre, 200',
    status: 'Completed',
  },
];

export const ORG_VEHICLE_COLUMNS = [
  {
    Header: 'Vehicle Make',
    accessor: 'vehicle_Make',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'Vehicle Model',
    accessor: 'vehicle_Model',
  },
  {
    Header: 'Drivers',
    accessor: 'drivers',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Users'
              column={ORG_VEHICLE_DRIVERS_MODAL_COLUMNS}
              row={ORG_VEHICLE_DRIVERS_MODAL_DATA}
            />
          )}
        </div>
      );
    },
  },

  {
    Header: 'Total Trips',
    accessor: 'totalTrips',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Trips'
              column={TRIPSCOLUMNS}
              row={TRIPSDATA}
            />
          )}
        </div>
      );
    },
  },

  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <Dropdown icon />,
  },
];

export const ORG_VEHICLE_DATA = [
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    totalTrips: 5,
    drivers: 3,
  },
];

export const ORG_VEHICLE_DRIVERS_MODAL_COLUMNS = [
  {
    Header: 'User Name',
    accessor: 'user_Name',
  },
  {
    Header: 'User Email',
    accessor: 'user_Email',
  },

  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <MdDeleteForever className='w-9 h-9 text-ezRed' />,
  },
];

export const ORG_VEHICLE_DRIVERS_MODAL_DATA = [
  {
    user_Name: 'John Victor',
    user_Email: 'john@gmail.com',
  },
];

// Advertisments Table Data

// export const ADS_COLUMNS = [
//   {
//     Header: 'Campaign',
//     accessor: 'title',
//     Cell: ({ row }) => {
//       const navigate = useNavigate();

//       return (
//         <button className="flex flex-row items-center hover:text-black" onClick={() => navigate(`/ads-management/${row?.original?._id}`)}>
//           <img
//             src={row?.original?.logo}
//             alt='Profile Avatar'
//             className='mr-2 h-8 w-8 relative rounded-full object-cover'
//           />
//           {row?.original?.title}
//         </button>
//       )
//     }
//   },
//   {
//     Header: 'Company',
//     accessor: 'company'
//   },
//   {
//     Header: 'Ad Heading',
//     accessor: 'heading'
//   },
//   {
//     Header: 'Website',
//     accessor: 'link'
//   },
//   {
//     Header: 'Start',
//     accessor: 'startDateAndTime',
//     Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : ''
//   },
//   {
//     Header: 'End',
//     accessor: 'endDateAndTime',
//     Cell: ({ value }) => value ? new Date(value).toLocaleDateString() : ''
//   },
//   {
//     Header: 'Status',
//     accessor: 'active',
//     Cell: ({ row }) =>
//     // <span className={`rounded-lg text-white py-1 px-2 ${row?.original?.active ? 'bg-ezGreen' : 'bg-ezRed'}`}>{row?.original?.active ? 'Active' : 'Inactive'}</span>
//     {
//       const { handleApiCall, isApiLoading } = useApiHook();
//       const [status, setStatus] = useState(row?.original?.active);

//       async function changeStatus() {
//         const result = await handleApiCall({
//           method: 'post',
//           url: `admin/ads/toggle-status/${row?.original?._id}`,
//         });
//         if (result?.status === 200) {
//           console.log(result?.data?.state);
//           toast.success(result?.data?.message);
//         }
//       };

//       return (
//         <div className='flex h-6 items-center'>
//           <Switch
//             checked={status}
//             onChange={() => {setStatus(!status); changeStatus()}}
//             className={`${
//               status ? 'bg-ezGreen' : 'bg-ezRed'
//             } flex w-8 flex-none cursor-pointer rounded-full p-px transition-colors duration-200 ease-in-out`}
//           >
//             <span
//               aria-hidden='true'
//               className={`${
//                 status
//                   ? 'translate-x-3.5'
//                   : 'translate-x-0'
//               } h-4 w-4 transform rounded-full bg-white shadow-sm  transition duration-200 ease-in-out`}
//             />
//           </Switch>
//         </div>
//       )
//     }
//   },
//   {
//     Header: 'Actions',
//     accessor: 'actions',
//     Cell: ({ row }) => {
//       const navigate = useNavigate();
//       const dispatch = useDispatch();

//       const handleSelectAndDelete = () => {
//         dispatch(setSelectedAd(row?.original));
//         dispatch(toggleIsDeleteModalOpen(true));
//       }

//       return (
//         <div className="flex items-center">
//           <button className="text-blue-600" onClick={() => navigate(`/ads-management/${row?.original?._id}`)}><AiFillEye className='w-4 md:w-6 h-4 md:h-6 text-ezGreen' /></button>
//           <button onClick={handleSelectAndDelete}><AiFillCloseCircle className='ml-2 w-3 md:w-5 h-3 md:h-5 text-ezRed' /></button>
//         </div>
//       );
//     }

//   },
// ]

// USers Table Data

export const USERS_COLUMNS = [
  {
    Header: 'Full Name',
    accessor: 'name',
    Cell: ({ row }) => {
      const dispatch = useDispatch();

      return (
        <NavLink
          onClick={() => dispatch(selectedUser(row?.original))}
          to={`/users/${row?.original?._id}`}
        >
          {row?.original?.name}
        </NavLink>
      );
    },
  },
  {
    Header: 'User Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  // {
  //   Header: 'Org Name',
  //   accessor: 'org_Name',
  // },
  {
    Header: 'Vehicles',
    accessor: 'vehicles',
    // Cell: ({ value }) => {
    //   const [modalOpen, setModalOpen] = useState(false);

    //   const handleViewClick = () => {
    //     setModalOpen(true);
    //   };

    //   return (
    //     <div className='flex items-center gap-1'>
    //       {value}
    //       <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
    //       <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
    //         view
    //       </p>
    //       {modalOpen && (
    //         <CommonTableModal isViewModal={modalOpen} setIsViewModal={setModalOpen}
    //           ModalHeader='Vehicle' column={USERS_VEHICLE_COLUMNS} row={ORG_VEHICLE_DATA} />
    //       )}
    //     </div>
    //   );
    // }
  },
  {
    Header: 'Trips',
    accessor: 'trips',
    // Cell: ({ value }) => {
    //   const [modalOpen, setModalOpen] = useState(false);

    //   const handleViewClick = () => {
    //     setModalOpen(true);
    //   };

    //   return (
    //     <div className='flex items-center gap-1'>
    //       {value}
    //       <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
    //       <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
    //         view
    //       </p>
    //       {modalOpen && (
    //         <CommonTableModal isViewModal={modalOpen} setIsViewModal={setModalOpen}
    //           ModalHeader='Trips' column={USERS_TRIPSCOLUMNS} row={TRIPSDATA} />
    //       )}
    //     </div>
    //   );
    // }
  },

  // {
  //   Header: 'State',
  //   accessor: 'state',

  // },
  // {
  //   Header: 'Country',
  //   accessor: 'country',

  // },
  // {
  //   Header: 'Zip',
  //   accessor: 'zip'
  // },
  // {
  //   Header: 'Actions',
  //   accessor: '_id',
  //   Cell: ({ value }) =>
  //   (
  //     <>
  //       <Dropdown icon />
  //     </>
  //   )

  // },
];

export const USERS_DATA = [
  {
    full_Name: 'Torie',
    user_Email: 'Rustman@gmail.com',
    org_Name: 'Geotab',
    vehicle: 2,
    trips: '5',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '33901',
  },
  {
    full_Name: 'Torie',
    user_Email: 'Rustman@gmail.com',
    org_Name: 'Geotab',
    vehicle: 4,
    trips: '5',
    city: 'Fort Myers',
    state: 'Florida',
    country: 'United States',
    zip: '33901',
  },
];

export const USERS_TRIPSCOLUMNS = [
  {
    Header: 'Trip ID',
    accessor: 'tripId',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },

  {
    Header: 'Route',
    accessor: 'route',
  },
  {
    Header: 'Vehicle',
    accessor: 'vehicle',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const USERS_VEHICLE_COLUMNS = [
  {
    Header: 'Vehicle Make',
    accessor: 'vehicle_Make',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'Vehicle Model',
    accessor: 'vehicle_Model',
  },
];
export const VEHICLE_COLUMNS = [
  {
    Header: 'Vehicle Make',
    accessor: 'vehicle_Make',
    Cell: ({ value }) => <NavLink to={`/vehicles/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'Vehicle Model',
    accessor: 'vehicle_Model',
  },
  {
    Header: 'Users',
    accessor: 'users',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Users'
              column={ORG_VEHICLE_DRIVERS_MODAL_COLUMNS}
              row={ORG_VEHICLE_DRIVERS_MODAL_DATA}
            />
          )}
        </div>
      );
    },
  },
];
export const VEHICLE_DATA = [
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    users: 5,
  },
];

export const ACTIVITIES_COLUMNS = [
  {
    Header: 'Tracking ID',
    accessor: 'trackingId',
    Cell: ({ value }) => (
      <NavLink to={`/charging-activities/${value}`}>{value}</NavLink>
    ),
  },
  {
    Header: 'User Name',
    accessor: 'user_Name',
  },
  {
    Header: 'Org Name',
    accessor: 'org_Name',
  },
  {
    Header: 'Connector Name',
    accessor: 'connector_Name',
  },
  {
    Header: 'Station Address',
    accessor: 'station_Address',
  },
  {
    Header: 'Vehicle Make, Model',
    accessor: 'vehicle',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <Dropdown icon />,
  },
];

export const ACTIVITIES_DATA = [
  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },
  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },
  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },
  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },
  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },

  {
    trackingId: 654214,
    user_Name: 'Demi Wilkinson',
    org_Name: 'Fleetio',
    connector_Name: 'EZVOLTZON001',
    station_Address: 'Trip to Florida, USA',
    vehicle: 'Dacia, Spring Electric 45',
    status: 'Charging',
  },
];

export const USER_DETAIL_VEHICLE_COLUMNS = [
  {
    Header: 'Vehicle ID',
    accessor: '_id',
  },
  {
    Header: 'Vehicle Make',
    accessor: 'make.name',
    Cell: ({ value }) => <NavLink to={`/trips/${value}`}>{value}</NavLink>,
  },
  {
    Header: 'Vehicle Model',
    accessor: 'model.model',
  },
  {
    Header: 'Trips',
    accessor: 'trips',
  },
];

export const USER_DETAIL_VEHICLE_DATA = [
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    trips: 5,
    plate_Number: 'ABCDKHSD',
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    trips: 5,
    plate_Number: 'ABCDKHSD',
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    trips: 5,
    plate_Number: 'ABCDKHSD',
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    trips: 5,
    plate_Number: 'ABCDKHSD',
  },
  {
    vehicle_Make: 'Rolls-Royce',
    vehicle_Model: 'spectre',
    trips: 5,
    plate_Number: 'ABCDKHSD',
  },
];

export const USER_DETAIL_TRIPS_COLUMNS = [
  {
    Header: 'Trip ID',
    accessor: '_id',
  },
  {
    Header: 'From',
    accessor: 'destination.text',
  },
  {
    Header: 'To',
    accessor: 'origin.text',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : ''),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      return (
        <span
          className={`inline-flex capitalize items-center rounded-full ${
            value === 'completed'
              ? 'bg-completed text-completed'
              : value === 'inprogress'
              ? 'bg-inprogress text-inprogress'
              : value === 'cancelled'
              ? 'bg-cancelled text-cancelled'
              : value === 'upcoming'
              ? 'bg-upcoming text-upcoming'
              : 'bg-ezGray text-ezGray'
          } bg-opacity-20 px-2 py-1 text-xs font-medium `}
        >
          {value === 'inprogress'
            ? 'In Progress'
            : value === 'upcoming'
            ? 'Upcoming'
            : value === 'notStarted'
            ? 'Not Started'
            : value}
        </span>
      );
    },
  },
];

export const VEHICLE_DETAIL_USERS_COLUMNS = [
  {
    Header: 'Full Name',
    accessor: 'full_Name',
  },
  {
    Header: 'User Email',
    accessor: 'user_Email',
  },

  {
    Header: 'Trips',
    accessor: 'trips',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Trips'
              column={USERS_TRIPSCOLUMNS}
              row={TRIPSDATA}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: 'City',
    accessor: 'city',
  },

  {
    Header: 'State',
    accessor: 'state',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Zip',
    accessor: 'zip',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <MdDeleteForever className='w-8 h-8 text-ezRed' />,
  },
];

export const PAYMENT_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
  },

  {
    Header: 'Card Number',
    accessor: 'card_Number',
    Cell: ({ value }) => {
      const hiddenDigits =
        value.slice(0, 5) +
        ' ' +
        '*'.repeat(4) +
        ' ' +
        '*'.repeat(4) +
        ' ' +
        value.slice(14);
      return <span>{hiddenDigits}</span>;
    },
  },
  {
    Header: 'Card Holder',
    accessor: 'card_Holder',
  },
  {
    Header: 'Assigned User',
    accessor: 'assigned_User',
    Cell: ({ value }) => {
      const [modalOpen, setModalOpen] = useState(false);

      const handleViewClick = () => {
        setModalOpen(true);
      };

      return (
        <div className='flex items-center gap-1'>
          {value}
          <span className='w-1 h-1 bg-ezLightBlue inline-flex rounded-lg '></span>
          <p className='text-ezGreen cursor-pointer' onClick={handleViewClick}>
            view
          </p>
          {modalOpen && (
            <CommonTableModal
              isViewModal={modalOpen}
              setIsViewModal={setModalOpen}
              ModalHeader='Users'
              column={ORG_VEHICLE_DRIVERS_MODAL_COLUMNS}
              row={ORG_VEHICLE_DRIVERS_MODAL_DATA}
            />
          )}
        </div>
      );
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    Cell: () => <Dropdown icon />,
  },
];

export const PAYMENT_DATA = [
  {
    type: 'Visa',
    card_Number: '1354 1234 3456 8132',
    card_Holder: 'Waqas',
    assigned_User: 10,
  },
];
