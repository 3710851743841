import React from "react";
import PrimaryBtn from "components/btns/PrimaryBtn";
import { USERS_DATA, VEHICLE_DETAIL_USERS_COLUMNS } from "helper/data";
import { BasicTable } from "components/BasicTable/BasicTable";
import OutlineBtn from "components/btns/OutlineBtn";

const VehicleDetail = () => {
  return (
    <div className='ez__Home w-full relative z-1 p-5 pb-10 flex flex-col gap-5'>
      {/* header title */}
      <div className='flex flex-row justify-between items-center '>
        <h1 className='text-2xl font-semibold text-ezBlack '>Vehicle Detail</h1>
        <div className='flex gap-2'>
          <OutlineBtn
            btnType='submit'
            text='Delete'
            deletebtn
            //   handleEvent={() => setIsAddUserModal(true)}
          />
          <OutlineBtn
            btnType='submit'
            text='Edit'

            //   handleEvent={() => setIsAddUserModal(true)}
          />
        </div>
      </div>
      <div className='bg-ezMidGray p-5 rounded grid grid-cols-2 md:grid-cols-4 gap-3'>
        <div>
          <p className='text-xs font-light'>Make</p>
          <h5 className='text-sm font-medium'>Rolls-Royce </h5>
        </div>
        <div>
          <p className='text-xs font-light'>Model</p>
          <h5 className='text-sm font-medium'>Spectre</h5>
        </div>
        <div>
          <p className='text-xs font-light'>Range</p>
          <h5 className='text-sm font-medium'>200 KM</h5>
        </div>
        <div className='cursor-pointer'>
          <p className='text-xs font-light'>Plate No.</p>
          <h5 className='text-sm font-medium'>ABC-1234-20</h5>
        </div>
      </div>
      {/* tabs */}
      <div className='flex flex-col'>
        <div className='py-3'>
          <div className='ez__Home w-full relative z-1 pb-10'>
            <div className='flex flex-row justify-between items-center'>
              <h1 className='text-2xl font-semibold text-ezBlack'>Users</h1>
              <PrimaryBtn
                btnType='submit'
                text='Assign User'
                width
                //   handleEvent={() => setIsAddUserModal(true)}
              />
            </div>

            <div className=' w-full block card bg-white rounded-md shadow-sm  border'>
              <BasicTable
                COLUMNS={VEHICLE_DETAIL_USERS_COLUMNS}
                ROWS={USERS_DATA}
                paginationText='Users'
                view='users'
                searchText='Search trip ID'
              />
            </div>
            {/* 
              {addUserModal && (
                <AddVehicle isVehicleModal={addUserModal} setIsVehicleModal={setIsAddUserModal} />
              )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleDetail;
