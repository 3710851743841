const OutlineBtn = ({
  isApiLoading = false,
  btnType = "button",
  text,
  deletebtn,
  className='px-8 py-1.5',
  handleEvent = () => {},
}) => {
  return (
    <button
      type={btnType}
      onClick={handleEvent}
      disabled={isApiLoading}
      className={`border min-w-2 ${
        deletebtn
          ? "border-ezRed text-ezRed hover:bg-ezRed"
          : "border-ezGreen text-ezGreen hover:bg-ezGreen"
      }   ${className} bg-transparent  rounded-md text-center block  hover:text-white`}>
      {text}
    </button>
  );
};

export default OutlineBtn;
